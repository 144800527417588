import {useEffect, useState} from "react";
import {ApiConsumer} from "../../api/ApiConsumer";
import {Chart} from "react-google-charts";

export function HorizontalBar({url, title = "", height = "400"}) {
    const [data, setData] = useState([]);

    const colours = ["#5fa83f", "#f72585", "#118ab2", "#ff9770", "#d35400"];

    const options = {
        chart: {
            title: title,
            subtitle: "",
        },
        legend: {position: "right"},
        width: "100%",
        height: "400px",
        chartArea: {width: "75%", height: "65%", left: "7%"},
    };

    useEffect(() => {
        ApiConsumer.get(url).then(res => {
            console.log(res.data)
            setData(res.data);
        }).catch((err) => console.error(err))

    }, []);

    return <>
        <div className="min-w-0 flex-1">
            <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
                <h3 className="bg-white p-2 text-center">{title}</h3>
                <Chart
                    chartType="BarChart"
                    width="100%"
                    height={`${height}px`}
                    data={data}
                    options={options}
                    className={"shadow-2xl rounded"}
                />
            </div>
        </div>
    </>
}
