import { useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { useDataLoaderHook } from "../../hooks/data-loader";
import { API_ROUTES } from "../../routes/api";
import { GLOBAL } from "../../constants/global";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../components/alert";
import { AppLoaderContext } from "../../contexts/app-loader";
import ModalUserDetails from "../../components/modal-user-details";
import Modal from "../../components/modal";
import Select from "react-select";
import { useTermsHook } from "../../hooks/terms";

export default function AddEditTask({
    project,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    console.log(project);
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    let { loadDropdown } = useDataLoaderHook();
    let { getTermIndexByYear } = useTermsHook();
    const [maxValues, setMaxValues] = useState([]);
    const [yearIndex, setYearIndex] = useState(0);

    const [projects, setProjects] = useState([]);
    const [programmes, setProgrammes] = useState([]);
    const [kpas, setKpas] = useState([]);
    const [kpis, setKpis] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [responsiblePerson, setResponsiblePerson] = useState([]);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [{ name: "name", display: "task name" }];

    const numberFormatter = new Intl.NumberFormat("en-US");

    const validateAddEditTask = (isEdit) => {
        //Validate score card

        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.TASKS.TASK_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.TASKS.TASKS, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.PROJECT.PROJECTS, setProjects);
        loadDropdown(API_ROUTES.SECTION.SECTIONS, setProgrammes);
        loadDropdown(API_ROUTES.CONTENT.KPA, setKpas);
    }, []);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (user) =>
                        [
                            GLOBAL.USER_TYPES.STAFF_MEMBER,
                            GLOBAL.USER_TYPES.ADMINISTRATOR,
                        ].indexOf(user.user_type.name) !== -1
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setResponsiblePerson(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    useEffect(() => {
        /* Defaults */

        setFormDetails((prevState) => ({
            ...prevState,
            [`kpa`]:
                project.kpa?.id !== undefined ? project.kpa.id : "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpi`]: project?.indicator?.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme`]:
                project.programme?.id !== undefined
                    ? project.programme.id
                    : "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project`]: project?.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`start_date`]: new Date(project.start_date),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`end_date`]: new Date(project.end_date),
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`expenditure`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`budget`]: 0,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`actual_budget`]: 0,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`responsible_person`]: [],
        }));
    
        setFormDetails((prevState) => ({
            ...prevState,
            [`score_type`]: project.indicator.indicator_umo.name.toUpperCase(),
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`baseline`]: project.indicator.baseline===null? 0 : project.indicator.baseline,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`target`]: project.indicator.indicator_year5,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter1_max`]: project.indicator.q1,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter2_max`]: project.indicator.q2,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter3_max`]: project.indicator.q3,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter4_max`]: project.indicator.q4,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter1`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter2`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter3`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter4`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`progress`]: 0,
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`task_id`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`project`]: editableRow.project.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`kpa`]: editableRow.project.kpa.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme`]: editableRow.project.programme.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`start_date`]: new Date(editableRow.start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`end_date`]: new Date(editableRow.end_date),
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`expenditure`]: editableRow.expenditure,
            }));

            let responsible_person = [];
            editableRow.responsible_person?.forEach((manager, i) => {
                responsible_person[i] = manager.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`responsible_person`]: responsible_person,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`score_type`]:
                    project.indicator.indicator_umo.name.toUpperCase(),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`baseline`]: editableRow.kpi.baseline===null? 0 : editableRow.kpi.baseline,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`target`]: editableRow.kpi.indicator_year5,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter1_max`]: editableRow.kpi.q1,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter2_max`]: editableRow.kpi.q2,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter3_max`]: editableRow.kpi.q3,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter4_max`]: editableRow.kpi.q4,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`q1_total`]: editableRow.kpi.q1_total,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`q2_total`]: editableRow.kpi.q2_total,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`q3_total`]: editableRow.kpi.q3_total,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`q4_total`]: editableRow.kpi.q4_total,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter1`]: editableRow.quarter1,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter2`]: editableRow.quarter2,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter3`]: editableRow.quarter3,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`quarter4`]: editableRow.quarter4,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`progress`]: editableRow.progress,
            }));
        }

        return () => {
            setResponsiblePerson([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow, project]);

    /* useEffect(() => {
        setIndicators(
            project?.kpa?.indicators?.filter(
                (indicator) => indicator.active === true
            )
        );
        setKpis(
            project?.kpa?.indicators
                ?.filter((indicator) => indicator.active === true)
                .sort((a, b) => a.indicator_name.localeCompare(b.name))
        );
    }, [project]); */

    const updateScoreCard = (selectedIndicator) => {
        selectedIndicator = indicators.filter(
            (indicator) => indicator.id === selectedIndicator
        );

        setFormDetails((prevState) => ({
            ...prevState,
            [`score_type`]:
                selectedIndicator[0].indicator_umo.name.toUpperCase(),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`baseline`]: selectedIndicator[0].baseline===null? 0 : selectedIndicator[0].baseline,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`target`]: selectedIndicator[0].indicator_year5,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter1_max`]: selectedIndicator[0].q1,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter2_max`]: selectedIndicator[0].q2,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter3_max`]: selectedIndicator[0].q3,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter4_max`]: selectedIndicator[0].q4,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`q1_total`]: selectedIndicator[0].q1_total,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`q2_total`]: selectedIndicator[0].q2_total,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`q3_total`]: selectedIndicator[0].q3_total,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`q4_total`]: selectedIndicator[0].q4_total,
        }));
    };

    const resetQuarterlyTargets = function (quaters) {
        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            quaters.map((quarter) => {
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`quarter` + quarter]: null,
                }));
            });

            //Resest progress only if all quarters are affected
            if (quaters.length === 4) {
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`progress`]: 0,
                }));

                setFormError(
                    "Please note that updating changing the KPI will reset all the Quarterly Targets and Progress captured, should you proceed."
                );
            } else {
                setFormError(
                    "Please note that updating the Quarter " +
                        quaters[0] +
                        " Max will reset the Quarterly Target for that quarter, should you proceed."
                );
            }
        }
    };

    const restoreQuarterlyTargets = function (quaters) {
        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            quaters.map((quarter) => {
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`quarter` + quarter]: editableRow[`quarter` + quarter],
                }));
            });
            setFormError("");
        }
    };

    useEffect(() => {
        setYearIndex(getTermIndexByYear(project.kpa.term, project.year));
    }, [project]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6 mb-44"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditTask(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>
                        </>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    KPA:
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                {project?.kpa?.name}
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    KPI (indicator):
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                {project.indicator.indicator_name}
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="programme">
                                    Section/Group:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                {project?.programme?.name}
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="project">
                                    Project:
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                {project?.name}
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Task name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="start_date">
                                    Task start date: (optional)
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    minDate={new Date(project.start_date)}
                                    maxDate={new Date(project.end_date)}
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.start_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`start_date`]: event,
                                        }));
                                        if (
                                            isAfter(event, formDetails.end_date)
                                        )
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`end_date`]: event,
                                            }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="end_date">
                                    Task end date: (optional)
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    minDate={
                                        isAfter(
                                            new Date(project.start_date),
                                            formDetails.start_date
                                        )
                                            ? new Date(project.start_date)
                                            : new Date(formDetails.start_date)
                                    }
                                    maxDate={new Date(project.end_date)}
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.end_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`end_date`]: event,
                                        }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="expenditure"
                                >
                                    Actual expenditure (R):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="expenditure"
                                    name="expenditure"
                                    type="text"
                                    autoComplete="expenditure"
                                    className={`form-field ${
                                        invalidFormDetails.expenditure
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`expenditure`]: !isNaN(
                                                event.target.value
                                            )
                                                ? parseFloat(event.target.value)
                                                : 0,
                                        }));
                                    }}
                                    value={
                                        formDetails.expenditure
                                            ? formDetails.expenditure
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <p className="mt-4 pt-4 text-sm font-semibold border-t border-gray-200">
                                Task Accountability
                            </p>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="departments"
                                >
                                    Department / Division:
                                </label>
                            </div>
                            <div className="col-span-8">
                                {project?.departments?.map((department, i) => (
                                    <span
                                        key={i}
                                        className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-xs"
                                    >
                                        {department.name}
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="responsible_person"
                                >
                                    Responsible person:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="responsible_person"
                                    name="responsible_person"
                                    isMulti
                                    options={responsiblePerson}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.responsible_person
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`responsible_person`]: [
                                                ...event,
                                            ].map((option) => option.value),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(responsiblePerson)
                                            .length !== 0
                                            ? formDetails?.responsible_person?.map(
                                                  (selectedOption) => {
                                                      return responsiblePerson[
                                                          responsiblePerson.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Task`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } task:<br /><strong>${formDetails.name}</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
