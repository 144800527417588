import {EyeIcon, PlusCircleIcon} from "@heroicons/react/24/outline";
import Modal from "../modal";
import {useEffect, useState} from "react";
import AddEditAuditAction from "../forms/add-edit-audit-action";
import AddEditAuditFindings from "../forms/add-edit-audit-findings";
import ViewAuditFindings from "../forms/view-audit-findings";
import {ApiConsumer} from "../../api/ApiConsumer";
import {API_ROUTES} from "../../routes/api";

export default function AuditRowCard({row = {}, tableHeaders = [], tableData = [], i = 0, responsiblePerson = []}) {
    const [rowUpdated, setRowUpdated] = useState(false);
    const [openAddEditAuditFindings, setOpenAddEditAuditFindings] = useState(false);
    const [openAddEditAuditAction, setOpenAddEditAuditAction] = useState(false);
    const [editableAuditFindings, setEditableAuditFindings] = useState({});
    const [actions, setActions] = useState([]);

    function getValueByPath(obj, path) {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    }

    const onCloseViewAuditFindings = (modalStatus) => {
        if (modalStatus === false) {
            setEditableAuditFindings({});
        }
        setOpenAddEditAuditFindings(modalStatus);
    };

    const onCloseAddEditAuditActions = (modalStatus) => {
        if (modalStatus === false) {
            setEditableAuditFindings({});
        }
        setOpenAddEditAuditAction(modalStatus);
    };

    const formattableTypes = [
        'status',
        'risk_level',
        'priority',
        'completion_status'
    ]

    function formatStatus(type, status) {
        if (formattableTypes.indexOf(type) === -1) return status;
        let color = '';

        if (status === 'open' || status === 'high' || status === 'not_started') {
            color = 'bg-red-500';
        } else if (status === 'in_progress' || status === 'medium') {
            color = 'bg-amber-500';
        } else if (status === 'closed' || status === 'low' || status === 'completed') {
            color = 'bg-green-600';
        }
        return <span
            className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag ${color} text-white`}
        > {status} </span>
    }

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.AUDIT.ACTIONS_BY_FINDING(row?.id))
            .then((res) => {
                setActions(res.data);
            })
            .catch(err => console.error(err))
    }, []);

    return <>

        <Modal
            modalHeaderTag={
                `INDICATOR: ` + row?.indicator?.indicator_name
            }
            modalHeader={`Add Audit Finding Action`}
            modalContent={
                <AddEditAuditAction
                    kpi={row?.indicator}
                    findingId={row?.id}
                    setOpen={onCloseAddEditAuditActions}
                    editableRow={editableAuditFindings}
                    setRowUpdated={setRowUpdated}
                    responsiblePerson={responsiblePerson}
                />
            }
            open={openAddEditAuditAction}
            setOpen={onCloseAddEditAuditActions}
        />

        <Modal
            modalHeaderTag={
                `INDICATOR: ` + row?.indicator?.indicator_name
            }
            modalHeader={`View KPI Audit Findings`}
            modalContent={
                <ViewAuditFindings
                    kpi={row?.indicator}
                    setOpen={onCloseViewAuditFindings}
                    editableRow={row?.indicator}
                    setRowUpdated={setRowUpdated}
                />
            }
            open={openAddEditAuditFindings}
            setOpen={onCloseViewAuditFindings}
        />

        <tbody>
        <tr>
            <td>
                <table className="bg-white min-w-full rounded-tr-lg rounded-tl-lg">
                    <tbody>
                    <tr>
                        <td>
                            <table className="min-w-full ">
                                {tableHeaders
                                    .filter(
                                        (
                                            header
                                        ) =>
                                            header.showOnTable ===
                                            true
                                    )
                                    .map((column, j) => (
                                            <tbody key={j}>
                                            <tr>
                                                <td
                                                    className={`w-1/5 px-2 py-2 ${
                                                        j ===
                                                        0
                                                            ? `bg-gray-200 text-green-800 rounded-tl-lg font-bold text-md`
                                                            : `bg-white rounded-bl-lg text-sm`
                                                    }`}
                                                >
                                                    {tableHeaders[j].value + `:`}
                                                </td>
                                                <td
                                                    className={`w-4/5 px-2 py-2 rounded-tr-lg ${
                                                        j ===
                                                        0
                                                            ? `bg-gray-200 text-green-800 text-md font-bold`
                                                            : `bg-white rounded-br-lg text-sm`
                                                    }`}
                                                >
                                                    {formatStatus(tableHeaders[j].key, getValueByPath(tableData[i], tableHeaders[j].key))}
                                                </td>
                                            </tr>
                                            </tbody>
                                        )
                                    )}
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table className="bg-white min-w-full rounded-br-lg rounded-bl-lg mb-5 text-sm">
                    <thead className="bg-gray-100 text-left border-r border-l border-white">
                    <tr>
                        <th className="text-green-800 w-6/12 px-2 py-2">
                            <div className="grid grid-cols-12 bg-gray-200">
                                <div className="p-2 rounded col-span-11">
                                    Audit Actions
                                </div>
                                <div className="col-span-1 p-2 rounded">
                                    <PlusCircleIcon
                                        className={'text-green-800 h-6 cursor-pointer'}
                                        onClick={() => setOpenAddEditAuditAction(true)}/>
                                </div>

                            </div>

                        </th>

                        <th className="w-2/12 px-2 py-2 font-semibold">
                            Responsible Person
                        </th>
                        <th className="w-2/12 px-2 py-2 font-semibold">
                            Target Completion Date
                        </th>
                        <th className="w-2/12 px-2 py-2 font-semibold">
                            Completion Status
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        actions.map(action=>{
                            return (
                                <tr key={action?.id}>
                                    <td className="w-6/12 px-2 py-2">
                                        {action?.action_description}
                                    </td>
                                    <td className="w-2/12 px-2 py-2">
                                        {action?.responsible_party?.first_name} {action?.responsible_party?.last_name}
                                    </td>
                                    <td className="w-2/12 px-2 py-2">
                                        {action?.target_completion_date}
                                    </td>
                                    <td className="w-2/12 px-2 py-2">
                                        {formatStatus('completion_status', action?.completion_status)}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </>
}
