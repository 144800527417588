import { Routes, Route, Outlet } from "react-router-dom";
import { PORTAL } from "../routes/portal";
import Projects from "../pages/projects";
import Project from "../pages/project";

export default function ProjectRoutes() {
    return (
        <>
            <Routes>
                <Route index element={<Projects />} />
                <Route
                    path={
                        PORTAL.PROJECTS_BY_SECTION.replace(
                            PORTAL.PROJECTS,
                            ""
                        ) + `/:programme_id`
                    }
                    element={<Projects />}
                />
                <Route
                    path={
                        PORTAL.PROJECTS.replace(PORTAL.PROJECTS, "") + `/:year`
                    }
                    element={<Projects />}
                />
                <Route
                    path={
                        PORTAL.PROJECTS.replace(PORTAL.PROJECTS, "") +
                        `/:year/:kpa_id`
                    }
                    element={<Projects />}
                />
                <Route
                    path={
                        PORTAL.PROJECTS.replace(PORTAL.PROJECTS, "") +
                        `/:year/:kpa_id/:kpi_id`
                    }
                    element={<Projects />}
                />
                <Route
                    path={
                        PORTAL.PROJECTS_BY_USER.replace(PORTAL.PROJECTS, "") +
                        `/:user_id`
                    }
                    element={<Projects />}
                />
                <Route
                    path={PORTAL.PROJECT.replace(PORTAL.PROJECTS, "") + `/:project_id`}
                    element={<Project />}
                />
            </Routes>
            <Outlet />
        </>
    );
}
