import PieChartComponent from "../../components/charts/pie";

export default function InfrastructureDashboard() {


    return <>
        <div>
            <h1>Infrastructure Dashboard</h1>
            <PieChartComponent />
        </div>
    </>
}
