import {useEffect} from "react";
import AuditRowCard from "../cards/audit-row-card";

export default function TableAuditFindings({tableHeaders = [], tableData = [], loadData = {}, responsiblePerson=[]}) {
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div className="mx-full p-4 sm:p-6 lg:p-8">
                <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto overflow-hidden">
                        <table className="min-w-full">
                            {tableData.map((row, i) => (
                                <AuditRowCard key={i} tableHeaders={tableHeaders} tableData={tableData} row={row} i={i} responsiblePerson={responsiblePerson}/>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
