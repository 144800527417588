import { Fragment, useContext, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { differenceInHours } from 'date-fns'
import { NotificationContext } from '../contexts/notification'
import { notificationService } from '../services/notification'
import { useAuthentication } from '../hooks/authentication'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { NOTIFICATIONS_LIMIT, NOTIFICATIONS_TABLE } from '../config'

export default function Notifications() {
  let { showNotifications, setNotification } = useContext(NotificationContext)
  let { user } = useAuthentication()
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false) //TODO: Use this for the loader

  function getNotifications() {
    notificationService
      .from(NOTIFICATIONS_TABLE)
      .select('*')
      .eq('user_id', user()?.id)
      .order('created_at', { ascending: false })
      .then((res) => {
        if (Array.isArray(res.data))
          setNotifications(res.data.slice(0, NOTIFICATIONS_LIMIT))
      })
  }

  useEffect(() => {
    getNotifications()
  }, [showNotifications.popup])

  useEffect(() => {
    setNotification((prevState) => ({
      ...prevState,
      [`panel`]: false,
    }))
    getNotifications()
  }, [])
  return (
    <>
      <Transition
        show={showNotifications.panel}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="w-full absolute right-0 h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700 z-50 -mt-5"
          id="notification"
        >
          <div className="w-3/12 bg-gray-100 h-screen overflow-y-auto p-8 absolute right-0">
            <div className="flex items-center justify-between">
              <p className="text-2xl font-semibold">Notifications</p>
              <div className="cursor-pointer z-50">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setNotification((prevState) => ({
                      ...prevState,
                      [`panel`]: false,
                    }))
                  }}
                >
                  <path
                    d="M18 6L6 18"
                    stroke="#4B5563"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#4B5563"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <hr className="border-t-2 mt-3" />
            {!loading
              ? notifications.map((notification, i) => {
                  return (
                    <div
                      key={i}
                      className="w-full p-3 mt-4 bg-white rounded shadow flex flex-shrink-0"
                    >
                      <InformationCircleIcon
                        className="h-6 w-6 text-blue-500"
                        aria-hidden="true"
                      />

                      <div className="pl-2 w-full">
                        <div className="flex w-full">
                          <p className="text-sm">{notification?.message}</p>
                          <div
                            className="cursor-pointer"
                            onClick={async () => {
                              await notificationService
                                .from(NOTIFICATIONS_TABLE)
                                .delete()
                                .match({ id: notification.id })
                            }}
                          >
                            <svg
                              width={14}
                              height={14}
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.5 3.5L3.5 10.5"
                                stroke="#4B5563"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3.5 3.5L10.5 10.5"
                                stroke="#4B5563"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <p className="text-xs leading-3 pt-1 text-gray-500">
                          {differenceInHours(
                            new Date(),
                            new Date(notification.created_at),
                          )}{' '}
                          hours ago
                        </p>
                      </div>
                    </div>
                  )
                })
              : null}
            {!loading ? (
              notifications.length === 0 ? (
                <div className="w-full text-center bg-white rounded flex mt-3 p-3">
                  <p className="text-sm leading-3 pt-1 text-gray-500 text-center">
                    No notifications
                  </p>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </Transition>
    </>
  )
}
