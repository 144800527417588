import {APP_ID} from "../config";
import {PORTAL} from "../routes/portal";
import axios from 'axios';

export const ApiConsumer = axios.create()

export const isAuthenticated = () => {
    return !!sessionStorage.getItem(APP_ID);
}

export const UserId = () => {
    return JSON.parse(sessionStorage.getItem(APP_ID)).id
}

ApiConsumer.interceptors.request.use(request => {
        if (isAuthenticated()) {
            request.headers.Authorization = `Api-Key ${JSON.parse(sessionStorage.getItem(APP_ID))?.token}`;
            request.headers['User-Id'] = UserId()
        } else {
            window.location.replace(PORTAL.LOGIN);
        }
        return request;
    }, error => {
        return Promise.reject(error);
    }
);
