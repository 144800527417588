import { useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { APP_ID } from "../../config";
import { API_ROUTES } from "../../routes/api";
import { GLOBAL } from "../../constants/global";
import { ApiConsumer } from "../../api/ApiConsumer";
import { useDataLoaderHook } from "../../hooks/data-loader";
import * as Sentry from "@sentry/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../components/alert";
import Select from "react-select";
import { AppLoaderContext } from "../../contexts/app-loader";
import ModalUserDetails from "../../components/modal-user-details";
import Modal from "../../components/modal";

export default function AddEditSection({
    type,
    kpa,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { loadDropdown } = useDataLoaderHook();
    const [types, setTypes] = useState([]);
    const [kpas, setKpas] = useState([]);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [{ name: "name", display: "section/group name" }];

    const validateAddEditSection = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            console.log(formDetails);
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.SECTION.SECTION_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.SECTION.SECTIONS, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`overview`]: "",
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_id`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`overview`]: editableRow.overview,
            }));
        }

        return () => {
            setTypes([]);
            setKpas([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow, type, kpa]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditSection(Object.keys(editableRow).length);
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>
                        </>
                    )}

                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Section/Group name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="overview">
                                    Overview: (optional)
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="overview"
                                    name="overview"
                                    autoComplete="overview"
                                    className={`form-field ${
                                        invalidFormDetails.overview
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`overview`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.overview
                                            ? formDetails.overview
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Section/Group`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } section/group:<br /><strong>${
                            formDetails.name
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
