import { useState, useEffect } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { GLOBAL } from "../constants/global";
import { useAuthentication } from "../hooks/authentication";
import { PERMISSIONS } from "../constants/permissions";
import { API_ROUTES } from "../routes/api";
import ModalUserDetails from "../components/modal-user-details";
import Modal from "../components/modal";
import Status from "../components/progress-slider/status";
import Progress from "../components/progress-slider/progress";
import Bar from "../components/progress-slider/bar";
import DownloadAsPdf from "../components/share/download-pdf";
import Delete from "../components/delete";
import ViewUserDetails from "../components/view-user-details";

export default function Card({
    row,
    editable,
    setEditableRow,
    reloadTaskData,
    tableHeaders,
}) {
    const [showMoreText, setShowMoreText] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    let { hasPermission } = useAuthentication();
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
    });

    useEffect(() => {
        return () => {
            setShowMoreText(false);
        };
    }, []);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${row.created_by.first_name} ${row.created_by.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <li
                key={row.id}
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 flex flex-col text-sm"
            >
                <div className="w-full p-5 flex-1">
                    <div className="flex space-x-3">
                        <h2 className="h2 whitespace-normal text-lg mt-2">
                            {row.normalised.name}
                            {editable &&
                                !row.closed &&
                                hasPermission(
                                    PERMISSIONS.PROJECTS.CAN_EDIT_PROJECT
                                ) && (
                                    <button
                                        type="button"
                                        className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                    >
                                        <PencilIcon
                                            className="h-4 w-4 mr-1"
                                            aria-hidden="true"
                                            onClick={() => setEditableRow(row)}
                                        />
                                    </button>
                                )}
                        </h2>
                    </div>
                    <div>
                        <p className="text-gray-400 ">
                            <strong>KPI:</strong>{" "}
                            {row?.kpi?.indicator_name?.length > 120 ? (
                                <>
                                    {showMoreText
                                        ? row?.kpi?.indicator_name + ` `
                                        : row?.kpi?.indicator_name.substr(
                                              0,
                                              120
                                          ) + ` ... `}
                                    <span
                                        className="brand-link"
                                        onClick={() =>
                                            setShowMoreText(!showMoreText)
                                        }
                                    >
                                        show {showMoreText ? `less` : `more`}
                                    </span>
                                </>
                            ) : (
                                row?.kpi?.indicator_name
                            )}
                        </p>
                    </div>
                    <div className="items-center">
                        <p className="pt-1">
                            <strong>Date:</strong>{" "}
                            {row.normalised.start_date +
                                " - " +
                                row.normalised.end_date}
                        </p>
                        <p className="pt-1">
                            <strong>Duration:</strong> {row.normalised.duration}
                        </p>

                        <div className="pt-1">
                            <ViewUserDetails row={row} />
                        </div>

                        <p className="pt-1">
                            <strong>Actual Expentiture: </strong>
                            {row.normalised.expenditure}
                        </p>

                        <p className="pt-1">
                            <strong>Department / Division:</strong>{" "}
                            {row?.project?.departments?.map((department, i) => (
                                <span
                                    key={i}
                                    className="mr-1 rounded-md bg-gray-100 py-1 px-2 text-xs"
                                >
                                    {department.name}
                                </span>
                            ))}
                        </p>

                        
                    </div>
                </div>
                <div className="flex border-t border-gray-200 p-5 rounded-bl-lg rounded-br-lg bg-gray-100 ">
                    <div className="flex-1">
                        <span className="font-bold">Created:</span>{" "}
                        {format(new Date(row.created), "dd MMM yyyy")} by{" "}
                        <span
                            className="hover:underline cursor-pointer"
                            onClick={() => {
                                setUserDetailsRow(row.created_by);
                                setOpenUserDetails(true);
                            }}
                        >
                            {row.created_by.first_name +
                                " " +
                                row.created_by.last_name}
                        </span>{" "}
                        <br />
                        <span className="font-bold">Last updated:</span>{" "}
                        {row.normalised.updated}
                    </div>
                    <div className="float-right">
                        <DownloadAsPdf
                            tableTitle={`Task: `}
                            tableHeaders={tableHeaders}
                            tableData={[row]}
                            tableDataResult={row.name}
                            printType={GLOBAL.PRINT.PAGE}
                            filename={`Task: ${row.name}`}
                        />
                        {hasPermission(PERMISSIONS.TASKS.CAN_DELETE_TASK) && (
                            <Delete
                                apiRoute={API_ROUTES.TASKS.TASK_DETAIL}
                                rowData={row}
                                loadData={reloadTaskData}
                                titleValue={"Task"}
                            />
                        )}
                    </div>
                </div>
            </li>
        </>
    );
}
