import PageHeader from "../../components/page-header";
import TableAuditFindings from "../../components/tables/table-audit-findings";
import {useEffect, useState} from "react";
import {API_ROUTES} from "../../routes/api";
import {ApiConsumer} from "../../api/ApiConsumer";
import {GLOBAL} from "../../constants/global";
import * as Sentry from "@sentry/react";

export default function AuditingFindings() {
    const [audits, setAudits] = useState([]);
    const [responsiblePerson, setResponsiblePerson] = useState([]);
    const tableHeaders = [
        {
            key: "indicator.indicator_name",
            value: "KPI",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "description",
            value: "Finding",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "legislation_contravened",
            value: "Legislation Contravened",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "possible_impact",
            value: "Possible Impact",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created_by.first_name",
            value: "Reported by",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "risk_level",
            value: "Risk Level",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "priority",
            value: "Priority",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    function loadData() {
        ApiConsumer.get(API_ROUTES.AUDIT.FINDINGS).then((res) => {
            setAudits(res.data);
        }).catch(err=>console.error(err));
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (user) =>
                        [
                            GLOBAL.USER_TYPES.STAFF_MEMBER,
                            GLOBAL.USER_TYPES.ADMINISTRATOR,
                        ].indexOf(user.user_type.name) !== -1
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setResponsiblePerson(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    return <>
        <PageHeader pageHeaderName={`Audit Findings (${audits.length})`} />
        <TableAuditFindings tableHeaders={tableHeaders} tableData={audits} loadData={loadData} responsiblePerson={responsiblePerson}/>
    </>;
}
