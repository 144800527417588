import {Routes, Route, Outlet} from 'react-router-dom'
import {PORTAL} from './portal'
import AuditingDashboard from "../pages/auditing/auditing-dashboard";
import AuditingFindings from "../pages/auditing/auditing-findings";
import AuditingActions from "../pages/auditing/auditing-actions";

export default function AuditingRoutes() {

    return (
        <>
            <Routes>
                <Route index element={<AuditingDashboard/>}/>
                <Route
                    path={PORTAL.AUDITING.replace(PORTAL.AUDITING, '')}
                    element={<AuditingDashboard/>}
                />
                <Route
                    path={PORTAL.AUDITING_FINDINGS.replace(PORTAL.AUDITING, '')}
                    element={<AuditingFindings/>}
                />
                <Route
                    path={PORTAL.AUDITING_ACTIONS.replace(PORTAL.AUDITING, '')}
                    element={<AuditingActions/>}
                />
            </Routes>
            <Outlet/>
        </>
    )
}
