import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { API_ROUTES } from "../../routes/api";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../components/alert";
import { AppLoaderContext } from "../../contexts/app-loader";

export default function AddEditMeta({
    setOpen,
    editableRow,
    setRowUpdated,
    metaType,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [{ name: "name", display: metaType.toLowerCase() }];

    const validateAddEditMeta = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            if (isEdit) {
                let EditUrl = "";
                switch (metaType) {
                    case "Programme Category":
                        EditUrl = API_ROUTES.METADATA.SECTION.CATEGORY_DETAIL;
                        break;
                    case "Unit Of Measurement":
                        EditUrl = API_ROUTES.METADATA.UOM_DETAIL;
                        break;
                    case "Programme Tax Type":
                        EditUrl = API_ROUTES.METADATA.SECTION.TAX_TYPE_DETAIL;
                        break;
                    case "Internal Unit":
                        EditUrl =
                            API_ROUTES.METADATA.SECTION.INTERNAL_UNIT_DETAIL;
                        break;
                    case "Project Type":
                        EditUrl = API_ROUTES.METADATA.PROJECT.TYPE_DETAIL;
                        break;
                    case "Project Activity":
                        EditUrl = API_ROUTES.METADATA.PROJECT.ACTIVITY_DETAIL;
                        break;
                    case "Area of Support":
                        EditUrl =
                            API_ROUTES.METADATA.PROJECT.AREAS_OF_SUPPORT_DETAIL;
                        break;
                    case "NDP Chapter":
                        EditUrl =
                            API_ROUTES.METADATA.PROJECT.NDP_CHAPTERS_DETAIL;
                        break;
                    case "Partner":
                        EditUrl = API_ROUTES.METADATA.PROJECT.PARTNER_DETAIL;
                        break;
                    case "Programme Impact":
                        EditUrl = API_ROUTES.METADATA.SECTION.IMPACT_DETAIL;
                        break;
                    case "Project Impact":
                        EditUrl = API_ROUTES.METADATA.PROJECT.IMPACT_DETAIL;
                        break;
                    default:
                }
                ApiConsumer.put(EditUrl(editableRow.id), formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                let AddUrl = "";
                switch (metaType) {
                    case "Programme Category":
                        AddUrl = API_ROUTES.METADATA.SECTION.CATEGORIES;
                        break;
                    case "Unit Of Measurement":
                        AddUrl = API_ROUTES.METADATA.UOM;
                        break;
                    case "Programme Tax Type":
                        AddUrl = API_ROUTES.METADATA.SECTION.TAX_TYPES;
                        break;
                    case "Internal Unit":
                        AddUrl = API_ROUTES.METADATA.SECTION.INTERNAL_UNITS;
                        break;
                    case "Project Type":
                        AddUrl = API_ROUTES.METADATA.PROJECT.TYPES;
                        break;
                    case "Project Activity":
                        AddUrl = API_ROUTES.METADATA.PROJECT.ACTIVITY;
                        break;
                    case "Area of Support":
                        AddUrl = API_ROUTES.METADATA.PROJECT.AREAS_OF_SUPPORT;
                        break;
                    case "NDP Chapter":
                        AddUrl = API_ROUTES.METADATA.PROJECT.NDP_CHAPTERS;
                        break;
                    case "Partner":
                        AddUrl = API_ROUTES.METADATA.PROJECT.PARTNERS;
                        break;
                    case "Programme Impact":
                        AddUrl = API_ROUTES.METADATA.SECTION.IMPACT;
                        break;
                    case "Project Impact":
                        AddUrl = API_ROUTES.METADATA.PROJECT.IMPACT;
                        break;
                    default:
                }

                ApiConsumer.post(AddUrl, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
        }

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditMeta(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <span className="font-bold">Created:</span>{" "}
                            {format(
                                new Date(editableRow.created),
                                "dd MMM yyyy"
                            )}{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {format(
                                new Date(editableRow.updated),
                                "dd MMM yyyy"
                            )}{" "}
                        </div>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    {metaType}:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add ${metaType}`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } ${metaType.toLowerCase()}:<br /><strong>${
                            formDetails.name
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
