import { TableCellsIcon } from '@heroicons/react/24/outline'
import { ApiConsumer } from '../../api/ApiConsumer'
import { API_ROUTES } from '../../routes/api'
import * as Sentry from '@sentry/react'

export default function DownloadAsExcel({
  tableTitle,
  tableHeaders,
  tableData,
  tableDataResult,
}) {
  const generateData = () => {
    let printResults = []
    let printData = [...tableData]

    printData.sort((a, b) => a.name.localeCompare(b.name))

    printData.forEach((data, i) => {
      let normalised = { ...data.normalised }
      Object.entries(normalised).forEach(([key, value]) => {
        if (
          tableHeaders.filter(
            (header) => header.key === key && header.showOnPrint === false,
          ).length !== 0
        ) {
          delete normalised[key]
          return false
        }

        if (value === null || value === undefined) normalised[key] = '-'
        else if (typeof value === 'object')
          normalised[key] = data[key].toString() //Take original
        //Format Array
        if (Array.isArray(value)) normalised[key] = value.join(', ')
        //Format `Closed`
        if (key === 'name') normalised['name'] += data.closed ? ` (CLOSED)` : ``
      })
      printResults.push(normalised)
    })

    return printResults
  }

  const createHeaders = () => {
    let printHeaders = []
    tableHeaders
      .filter((header) => header.showOnPrint === true)
      .forEach((header) => {
        printHeaders.push({
          id: header.key,
          name: header.value,
        })
      })
    return printHeaders
  }
  const downloadExcelDocument = (name, data) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  const downloadAsExcel = () => {
    let colHeaders = createHeaders()
    let colData = generateData()

    let payload = {
      headers: colHeaders,
      data: colData,
      title: tableTitle,
    }

    ApiConsumer.post(API_ROUTES.CONTENT.DOWNLOAD, payload, {
      responseType: 'blob',
    })
      .then(({ data }) => {
        downloadExcelDocument(`${tableTitle}.xlsx`, data)
      })
      .catch((err) => Sentry.captureException(err))
  }

  return (
    <button type="button" className="icon-transparent bg-gray-100">
      <TableCellsIcon
        onClick={() => downloadAsExcel()}
        className="h-6 w-6"
        aria-hidden="true"
      />
    </button>
  )
}
