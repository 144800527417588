import { useEffect, useState, useContext } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import Modal from "../components/modal";
import Delete from "../components/delete";
import AddEditTerm from "../components/forms/add-edit-term";
import { useTextFormatHook } from "../hooks/text-formatter";
import { ApiConsumer } from "../api/ApiConsumer";
import { Navigate, NavLink } from "react-router-dom";
import { API_ROUTES } from "../routes/api";
import { PORTAL } from "../routes/portal";
import { APP_ID } from "../config";
import { GLOBAL } from "../constants/global";
import { ActiveTermContext } from "../contexts/active-term";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";

export default function Terms() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    let { normaliseTableData } = useTextFormatHook();
    const [terms, setTerms] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditTerm, setOpenAddEditTerm] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    let { setActiveTerm } = useContext(ActiveTermContext);

    const pageHeaderButtons = [
        <button
            type="button"
            onClick={() => setOpenAddEditTerm(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add Term
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "Term",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "year1_end_date",
            value: "Year 1",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "year2_end_date",
            value: "Year 2",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "year3_end_date",
            value: "Year 3",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "year4_end_date",
            value: "Year 4",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "year5_end_date",
            value: "Year 5",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.KPAS_BY_TERM}/${row.id}`}
                        onClick={() => setActiveTerm(row)}
                        className={`brand-link`}
                    >
                        {row.name}
                    </NavLink>
                    <Delete
                        apiRoute={API_ROUTES.CONTENT.TERM_DETAIL}
                        rowData={row}
                        loadData={loadData}
                        titleValue={"Term"}
                    />
                </>
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.TERM)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.year1_end_date.localeCompare(b.year1_end_date)
                );
                res.data = res.data.map((term) => {
                    return {
                        ...term,
                        name:
                            format(
                                new Date(term.year1_start_date),
                                "dd MMM yyyy"
                            ) +
                            ` to ` +
                            format(
                                new Date(term.year5_end_date),
                                "dd MMM yyyy"
                            ),
                    };
                });
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setTerms(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setTerms({});
            setLoaded(false);
            setOpenAddEditTerm(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditTerm = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditTerm(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditTerm(false)
            : setOpenAddEditTerm(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
        return <Navigate to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Term`
                        : `Edit Term: ${editableRow.name}`
                }
                modalContent={
                    <AddEditTerm
                        setOpen={onCloseAddEditTerm}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditTerm}
                setOpen={onCloseAddEditTerm}
            />

            <PageHeader
                pageHeaderName={`IDP Terms (${terms?.length})`}
                pageHeaderButtons={pageHeaderButtons}
            />

            {loaded && (
                <Table
                    tableTitle={`Terms`}
                    tableHeaders={tableHeaders}
                    tableData={terms}
                    editable={true}
                    setEditableRow={setEditableRow}
                />
            )}
        </>
    );
}
