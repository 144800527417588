import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "../hooks/form-validation";
import Alert from "../components/alert";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "../api/ApiConsumer";
import { API_ROUTES } from "../routes/api";
import { format, compareDesc } from "date-fns";
import { AuthenticationContext } from "../contexts/authentication";
import Delete from "../components/delete";
import ModalUserDetails from "../components/modal-user-details";
import Modal from "../components/modal";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { AppLoaderContext } from "../contexts/app-loader";
import DownloadAsPdf from "../components/share/download-pdf";
import { GLOBAL } from "../constants/global";
import { useTextFormatHook } from "../hooks/text-formatter";

export default function Challenges({ type, route, obj, quarter = null }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [challenges, setChallenges] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    let { normaliseTableData } = useTextFormatHook();

    const formFields = [
        { name: "quarter", display: "quarter" },
        { name: "challenges", display: "challenges" },
        { name: "corrective_action", display: "corrective action" },
    ];

    const validateAddChallenge = () => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            ApiConsumer.post(API_ROUTES.CHALLENGES.CHALLENGES, formDetails)
                .then((res) => {
                    loadChallenges();
                    setFormSubmitted(true);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`quarter`]: quarter !== null ? quarter : "selected",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`challenges`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`corrective_action`]: "",
                    }));
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const loadChallenges = () => {
        ApiConsumer.get(route(obj.id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                console.log(res.data);
                setChallenges(
                    quarter !== null
                        ? res.data.filter(
                              (challenge) =>
                                  parseInt(challenge.quarter) === quarter
                          )
                        : res.data
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };

    useEffect(() => {
        loadChallenges();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: obj.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter`]: quarter !== null ? quarter : "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`challenges`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`corrective_action`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`created_by`]: currentUser.id,
        }));

        return () => {
            setChallenges([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [obj.id, currentUser.id]);


    const ChallengesTableHeaders = [
        { key: "quarter", value: "Quarter", showOnTable: true, showOnPrint: true },
        {
            key: "challenges",
            value: "Challenge",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "corrective_action",
            value: "Corrective Action",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "created_by",
            value: "Posted By",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "created",
            value: "Posted On",
            showOnTable: true,
            showOnPrint: true,
        }];

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="">
                <div className={`${"pb-5"}`}>
                    <h2
                        id="notes-title"
                        className="text-lg font-medium text-gray-900"
                    >
                        <strong>
                            {quarter !== null ? "Quarter " + quarter + " " : ""}{" "}
                            Challenges ({challenges.length})
                        </strong>
                    </h2>
                </div>

                <div className="space-x-3 bg-gray-100 px-4 py-6 sm:px-6">
                    <form
                        className="space-y-1 relative"
                        onSubmit={(event) => {
                            event.preventDefault();
                            validateAddChallenge();
                        }}
                    >
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="quarter">
                                    Quarter:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="quarter"
                                    name="quarter"
                                    autoComplete="quarter"
                                    className={`${
                                        invalidFormDetails.quarter
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`quarter`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.quarter
                                            ? formDetails.quarter
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select Quarter
                                    </option>
                                    <option value="1">Quarter 1</option>
                                    <option value="2">Quarter 2</option>
                                    <option value="3">Quarter 3</option>
                                    <option value="4">Quarter 4</option>
                                    <option value="0">Annual/Overall</option>
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="challenges">
                                    Challenge:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="challenges"
                                    name="challenges"
                                    autoComplete="challenges"
                                    className={`form-field ${
                                        invalidFormDetails.challenges
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`challenges`]: event.target.value,
                                        }))
                                    }
                                    value={
                                        formDetails.challenges
                                            ? formDetails.challenges
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="corrective_action"
                                >
                                    Corrective Action:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="corrective_action"
                                    name="corrective_action"
                                    autoComplete="corrective_action"
                                    className={`form-field ${
                                        invalidFormDetails.corrective_action
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) =>
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`corrective_action`]:
                                                event.target.value,
                                        }))
                                    }
                                    value={
                                        formDetails.corrective_action
                                            ? formDetails.corrective_action
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>
                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-right">
                                {formError}
                            </div>
                        )}
                        {formSubmitted && (
                            <Alert
                                type={`success`}
                                message={`Challenge has been successfully submit.`}
                            />
                        )}
                        <div className="mt-2 float-right">
                            <button type="submit" className="button">
                                Submit Challenge
                            </button>
                        </div>
                        <div className="clear-both"></div>
                    </form>
                </div>

                <div
                    className={`${
                        type === "task" ? "px-4 py-6 sm:px-6" : "py-6"
                    }`}
                >

                    {challenges.length ? <>
                        <div className="text-center mb-4"><DownloadAsPdf
                            tableTitle={`Project Challenges:`}
                            tableHeaders={ChallengesTableHeaders}
                            tableData={normaliseTableData(ChallengesTableHeaders, challenges)}
                            tableDataResult={obj?.name}
                            printType={GLOBAL.PRINT.TABLE}
                            filename={`Project Challenges: ${obj?.name}`}
                            downloadIconText={"Download Challenges"}
                        />
                        </div>
                        <div className="clear-both"></div>
                        </>
                      : null
                    }

                    <ul className="space-y-8">
                        {challenges.length
                            ? challenges.map((challenge, i) => (
                                  <li key={i}>
                                      <div className="flex space-x-3">
                                          <div className="flex-shrink-0">
                                              <span className="icon-transparent p-2 bg-orange-500 text-orange-100 hover:text-orange-100">
                                                  {challenge.created_by.first_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      challenge.created_by.last_name
                                                          .charAt(0)
                                                          .toUpperCase()}
                                              </span>
                                              {challenge.created_by.id ===
                                                  currentUser.id && (
                                                  <Delete
                                                      apiRoute={
                                                          API_ROUTES.CHALLENGES
                                                              .CHALLENGE_DETAIL
                                                      }
                                                      rowData={challenge}
                                                      loadData={loadChallenges}
                                                      titleValue={"Challenge"}
                                                  />
                                              )}
                                          </div>
                                          <div>
                                              <div className="font-medium">
                                                  Posted By:{" "}
                                                  <span
                                                      className="hover:underline cursor-pointer"
                                                      onClick={() => {
                                                          setUserDetailsRow(
                                                              challenge.created_by
                                                          );
                                                          setOpenUserDetails(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      {challenge.created_by
                                                          .first_name +
                                                          " " +
                                                          challenge.created_by
                                                              .last_name}
                                                  </span>
                                                  <span className=" font-normal text-sm ml-1">
                                                      (For{" "}
                                                      {parseInt(
                                                          challenge.quarter
                                                      ) === 0
                                                          ? `Annual/Overall`
                                                          : `Quarter ` +
                                                            challenge.quarter}
                                                      )
                                                  </span>
                                              </div>
                                              <div>
                                                  <span className="text-gray-400 text-sm">
                                                      {format(
                                                          new Date(
                                                              challenge.created
                                                          ),
                                                          "dd MMM yyyy HH:mm:ss"
                                                      )}
                                                  </span>
                                              </div>
                                              <div className="mt-1 text-sm">
                                                  <span className="font-medium">
                                                      Challenge:{" "}
                                                      <span className="text-gray-700">
                                                          {challenge.challenges}
                                                      </span>
                                                  </span>
                                              </div>
                                              <div className="mt-1 text-sm">
                                                  <span className="font-medium">
                                                      Corrective Action:{" "}
                                                      <span className="text-gray-700">
                                                          {
                                                              challenge.corrective_action
                                                          }
                                                      </span>
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              ))
                            : "There are no challenges posted."}
                    </ul>
                </div>
            </div>
        </>
    );
}
