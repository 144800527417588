import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useFormValidation } from "../../hooks/form-validation";
import { API_ROUTES } from "../../routes/api";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import Alert from "../../components/alert";
import { AppLoaderContext } from "../../contexts/app-loader";
import {GLOBAL} from "../../constants/global";

export default function AddEditAuditFindings({
                                                 kpi,
                                                 setOpen,
                                                 editableRow,
                                                 setRowUpdated
                                             }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [responsiblePerson, setResponsiblePerson] = useState([]);

    const formFields = [
        { name: "description", display: "Finding Description" },
        { name: "legislation_contravened", display: "Legislation Contravened" },
        { name: "possible_impact", display: "Possible Impact" },
        { name: "finding_date", display: "Finding Date", type: "date" },
        { name: "root_cause", display: "Root Cause" },
    ];

    const dropdownFields = [
        {
            name: "severity",
            display: "Severity",
            options: [
                { value: "low", label: "Low" },
                { value: "medium", label: "Medium" },
                { value: "high", label: "High" },
                { value: "critical", label: "Critical" },
            ],
        },
        {
            name: "status",
            display: "Status",
            options: [
                { value: "open", label: "Open" },
                { value: "in_progress", label: "In Progress" },
                { value: "closed", label: "Closed" },
            ],
        },
        {
            name: "risk_level",
            display: "Risk Level",
            options: [
                { value: "low", label: "Low" },
                { value: "medium", label: "Medium" },
                { value: "high", label: "High" },
            ],
        },
        {
            name: "priority",
            display: "Priority",
            options: [
                { value: "low", label: "Low" },
                { value: "medium", label: "Medium" },
                { value: "high", label: "High" },
            ],
        },
    ];

    const validateAddEditAuditFinding = () => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            ApiConsumer.post(API_ROUTES.AUDIT.FINDINGS, formDetails)
                .then((res) => {
                    setRowUpdated(true);
                    setFormSubmitted(true);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        /* Set default values */
        setFormDetails({
            description: "",
            legislation_contravened: "",
            possible_impact: "",
            finding_date: "",
            root_cause: "",
            responsible_person: [],
            severity: "medium",
            status: "open",
            risk_level: "low",
            priority: "low",
            indicator: editableRow?.id,
            kpa: kpi?.kpa?.id,
        });

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (user) =>
                        [
                            GLOBAL.USER_TYPES.STAFF_MEMBER,
                            GLOBAL.USER_TYPES.ADMINISTRATOR,
                        ].indexOf(user.user_type.name) !== -1
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setResponsiblePerson(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditAuditFinding();
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    <div className="space-y-2">
                        {formFields.map(({ name, display, type }) => (
                            <div className="grid grid-cols-12" key={name}>
                                <div className="col-span-4">
                                    <label className="text-sm" htmlFor={name}>
                                        {display}:
                                    </label>
                                </div>
                                <div className="col-span-8">
                                    <input
                                        type={type || "text"}
                                        id={name}
                                        name={name}
                                        autoComplete={name}
                                        className={`form-field ${
                                            invalidFormDetails[name]
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [name]: event.target.value,
                                            }));
                                        }}
                                        value={formDetails[name] || ""}
                                    />
                                </div>
                            </div>
                        ))}

                        {dropdownFields.map(({ name, display, options }) => (
                            <div className="grid grid-cols-12" key={name}>
                                <div className="col-span-4">
                                    <label className="text-sm" htmlFor={name}>
                                        {display}:
                                    </label>
                                </div>
                                <div className="col-span-8">
                                    <select
                                        id={name}
                                        name={name}
                                        className={`form-field ${
                                            invalidFormDetails[name]
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [name]: event.target.value,
                                            }));
                                        }}
                                        value={formDetails[name] || ""}
                                    >
                                        {options.map(({ value, label }) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ))}

                        {/* Responsible Person - Multi-Select */}
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="responsible_person"
                                >
                                    Responsible person:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="responsible_person"
                                    name="responsible_person"
                                    placeholder="Responsible Person"
                                    isMulti={false}
                                    options={responsiblePerson}
                                    classNamePrefix="multi-select"
                                    className={`multi-select text-xs`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`responsible_person`]: event.value
                                        }));
                                    }}
                                    defaultValue={0}
                                />
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                Add Audit Finding
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully added audit finding`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
