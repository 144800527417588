import { useEffect, useState } from "react";
import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import PageHeader from "../components/page-header";
import Modal from "../components/modal";
import Delete from "../components/delete";
import AddEditMeta from "../components/forms/add-edit-meta";
import { Navigate } from "react-router-dom";
import { useTextFormatHook } from "../hooks/text-formatter";
import { ApiConsumer } from "../api/ApiConsumer";
import { API_ROUTES } from "../routes/api";
import { PORTAL } from "../routes/portal";
import { APP_ID } from "../config";
import { GLOBAL } from "../constants/global";
import * as Sentry from "@sentry/react";

export default function Meta() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    let { normaliseTableData } = useTextFormatHook();
    const [ndpChapers, setNdpChapers] = useState([]);
    const [uoms, setUoms] = useState([]);
    const [projectTypes, setProjectTypes] = useState([]);
    const [projectActivities, setProjectActivities] = useState([]);
    const [partners, setPartners] = useState([]);

    const [metaType, setMetaType] = useState("");
    const [openAddEditMeta, setOpenAddEditMeta] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const pageHeaderButtons = [];

    const tableHeaders = [
        { key: "name", value: "Name", showOnTable: true, showOnPrint: true },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {});
        return tableData;
    };

    const loadMeta = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setMethod(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };
    const loadData = () => {
        loadMeta(API_ROUTES.METADATA.PROJECT.NDP_CHAPTERS, setNdpChapers);
        loadMeta(API_ROUTES.METADATA.UOM, setUoms);
        loadMeta(API_ROUTES.METADATA.PROJECT.TYPES, setProjectTypes);
        loadMeta(API_ROUTES.METADATA.PROJECT.ACTIVITY, setProjectActivities);
        loadMeta(API_ROUTES.METADATA.PROJECT.PARTNERS, setPartners);
    };

    useEffect(() => {
        loadData();
        return () => {
            setNdpChapers([]);
            setUoms([]);
            setProjectTypes([]);
            setProjectActivities([]);
            setPartners([]);
            setMetaType("");
            setOpenAddEditMeta(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditMeta = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditMeta(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditMeta(false)
            : setOpenAddEditMeta(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
        return <Navigate to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New ${metaType}`
                        : `Edit ${metaType}: ${editableRow.name}`
                }
                modalContent={
                    <AddEditMeta
                        setOpen={onCloseAddEditMeta}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                        metaType={metaType}
                    />
                }
                open={openAddEditMeta}
                setOpen={onCloseAddEditMeta}
            />

            <PageHeader
                pageHeaderName={`System Meta`}
                pageHeaderButtons={pageHeaderButtons}
            />

            <div className="mx-full px-4 sm:px-6 lg:px-8 my-5">
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full p-5">
                            <h2 className="h2 whitespace-normal text-xl">
                                NDP Chapters
                            </h2>
                            <div className=" divide-y divide-gray-100">
                                <p className="text-gray-400 mb-4">
                                    List of NDP Chapters
                                </p>

                                {ndpChapers?.map((chapter, i) => (
                                    <div key={i} className="py-3">
                                        {chapter.name}
                                        <button
                                            type="button"
                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                        >
                                            <PencilIcon
                                                className="h-4 w-4 mr-1"
                                                aria-hidden="true"
                                                onClick={() => {
                                                    setMetaType("NDP Chapter");
                                                    setEditableRow(chapter);
                                                }}
                                            />
                                        </button>

                                        <Delete
                                            apiRoute={
                                                API_ROUTES.METADATA.PROJECT
                                                    .NDP_CHAPTERS_DETAIL
                                            }
                                            rowData={chapter}
                                            loadData={loadData}
                                            titleValue={"NDP Chapter"}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-5">
                                <button
                                    type="button"
                                    className={`button text-left`}
                                    onClick={() => {
                                        setMetaType("NDP Chapter");
                                        setOpenAddEditMeta(true);
                                    }}
                                >
                                    <span className="button-icon">
                                        <PlusCircleIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Add NDP Chapter
                                </button>
                            </div>
                        </div>
                    </li>

                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full p-5">
                            <h2 className="h2 whitespace-normal text-xl">
                                Units Of Measurement
                            </h2>

                            <div className=" divide-y divide-gray-100">
                                <p className="text-gray-400 mb-4">
                                    List of units of measurement
                                </p>

                                {uoms?.map((uom, i) => (
                                    <div key={i} className="py-3">
                                        {uom.name}
                                        <button
                                            type="button"
                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                        >
                                            <PencilIcon
                                                className="h-4 w-4 mr-1"
                                                aria-hidden="true"
                                                onClick={() => {
                                                    setMetaType(
                                                        "Unit Of Measurement"
                                                    );
                                                    setEditableRow(uom);
                                                }}
                                            />
                                        </button>

                                        <Delete
                                            apiRoute={
                                                API_ROUTES.METADATA.UOM_DETAIL
                                            }
                                            rowData={uom}
                                            loadData={loadData}
                                            titleValue={"Unit Of Measurement"}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className="mt-5">
                                <button
                                    type="button"
                                    className={`button text-left`}
                                    onClick={() => {
                                        setMetaType("Unit Of Measurement");
                                        setOpenAddEditMeta(true);
                                    }}
                                >
                                    <span className="button-icon">
                                        <PlusCircleIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Add Unit Of Measurement
                                </button>
                            </div>
                        </div>
                    </li>

                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full p-5">
                            <h2 className="h2 whitespace-normal text-xl">
                                Project Types
                            </h2>
                            <div className=" divide-y divide-gray-100">
                                <p className="text-gray-400 mb-4">
                                    List of project types
                                </p>

                                {projectTypes?.map((projectType, i) => (
                                    <div key={i} className="py-3">
                                        {projectType.name}
                                        <button
                                            type="button"
                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                        >
                                            <PencilIcon
                                                className="h-4 w-4 mr-1"
                                                aria-hidden="true"
                                                onClick={() => {
                                                    setMetaType("Project Type");
                                                    setEditableRow(projectType);
                                                }}
                                            />
                                        </button>
                                        <Delete
                                            apiRoute={
                                                API_ROUTES.METADATA.PROJECT
                                                    .TYPE_DETAIL
                                            }
                                            rowData={projectType}
                                            loadData={loadData}
                                            titleValue={"Project Type"}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-5">
                                <button
                                    type="button"
                                    className={`button text-left`}
                                    onClick={() => {
                                        setMetaType("Project Type");
                                        setOpenAddEditMeta(true);
                                    }}
                                >
                                    <span className="button-icon">
                                        <PlusCircleIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Add Project Type
                                </button>
                            </div>
                        </div>
                    </li>

                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full p-5">
                            <h2 className="h2 whitespace-normal text-xl">
                                Project Activities
                            </h2>
                            <div className=" divide-y divide-gray-100">
                                <p className="text-gray-400 mb-4">
                                    List of project activities
                                </p>

                                {projectActivities?.map(
                                    (projectActivity, i) => (
                                        <div key={i} className="py-3">
                                            {projectActivity.name}
                                            <button
                                                type="button"
                                                className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                            >
                                                <PencilIcon
                                                    className="h-4 w-4 mr-1"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        setMetaType(
                                                            "Project Activity"
                                                        );
                                                        setEditableRow(
                                                            projectActivity
                                                        );
                                                    }}
                                                />
                                            </button>

                                            <Delete
                                                apiRoute={
                                                    API_ROUTES.METADATA.PROJECT
                                                        .ACTIVITY_DETAIL
                                                }
                                                rowData={projectActivity}
                                                loadData={loadData}
                                                titleValue={"Project Activity"}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="mt-5">
                                <button
                                    type="button"
                                    className={`button text-left`}
                                    onClick={() => {
                                        setMetaType("Project Activity");
                                        setOpenAddEditMeta(true);
                                    }}
                                >
                                    <span className="button-icon">
                                        <PlusCircleIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Add Project Activity
                                </button>
                            </div>
                        </div>
                    </li>

                    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                        <div className="w-full p-5">
                            <h2 className="h2 whitespace-normal text-xl">
                                Funding Partners
                            </h2>
                            <div className=" divide-y divide-gray-100">
                                <p className="text-gray-400 mb-4">
                                    List of funding partners
                                </p>

                                {partners?.map((partner, i) => (
                                    <div key={i} className="py-3">
                                        {partner.name}
                                        <button
                                            type="button"
                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                        >
                                            <PencilIcon
                                                className="h-4 w-4 mr-1"
                                                aria-hidden="true"
                                                onClick={() => {
                                                    setMetaType("Partner");
                                                    setEditableRow(partner);
                                                }}
                                            />
                                        </button>
                                        <Delete
                                            apiRoute={
                                                API_ROUTES.METADATA.PROJECT
                                                    .PARTNER_DETAIL
                                            }
                                            rowData={partner}
                                            loadData={loadData}
                                            titleValue={"Partner"}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-5">
                                <button
                                    type="button"
                                    className={`button text-left`}
                                    onClick={() => {
                                        setMetaType("Partner");
                                        setOpenAddEditMeta(true);
                                    }}
                                >
                                    <span className="button-icon">
                                        <PlusCircleIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Add Funding Partner
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}
