import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import TableHeader from "../components/table-header";
import { PencilIcon } from "@heroicons/react/24/outline";
import AddEditKpaIndicator from "../components/forms/add-edit-kpa-indicator";
import Modal from "../components/modal";
import { PORTAL } from "../routes/portal";
import { GLOBAL } from "../constants/global";
import { getYear } from "date-fns";
import { API_ROUTES } from "../routes/api";
import Delete from "../components/delete";
import { format } from "date-fns";
import { useTermsHook } from "../hooks/terms";

export default function TableKPA({
    term,
    tableTitle = "",
    tableHeaders = [],
    tableData = [],
    editable = false,
    setEditableRow = {},
    loadData = {},
}) {
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [openAddEditIndicator, setOpenAddEditIndicator] = useState(false);
    const [editableIndicator, setEditableIndicator] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [kpa, setKpa] = useState({});
    let { getTermYearByIndex } = useTermsHook();

    useEffect(() => {
        loadData();
        return () => {
            setOpenAddEditIndicator(false);
            setEditableIndicator({});
        };
    }, []);

    const onCloseAddEditIndicators = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableIndicator({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditIndicator(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableIndicator).length === 0
            ? setOpenAddEditIndicator(false)
            : setOpenAddEditIndicator(true);
    }, [editableIndicator]);

    useEffect(() => {
        loadData();
        return () => {
            setOpenAddEditIndicator(false);
            setEditableIndicator({});
            setRowUpdated(false);
        };
    }, []);

    return (
        <>
            <Modal
                modalHeaderTag={
                    term.length != 0
                        ? `TERM: ` +
                          format(
                              new Date(term?.year1_start_date),
                              "dd MMM yyyy"
                          ) +
                          ` to ` +
                          format(
                              new Date(term?.year5_end_date),
                              "dd MMM yyyy"
                          ) +
                          ``
                        : ``
                }
                modalHeader={
                    Object.keys(editableIndicator).length === 0
                        ? `Add New IDP Indicator`
                        : `Edit IDP Indicator: ${editableIndicator.indicator_name}`
                }
                modalContent={
                    <AddEditKpaIndicator
                        kpa={kpa}
                        setOpen={onCloseAddEditIndicators}
                        editableRow={editableIndicator}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditIndicator}
                setOpen={onCloseAddEditIndicators}
            />

            <TableHeader
                tableTitle={tableTitle}
                tableHeaders={tableHeaders}
                tableData={tableData}
                filterString={filterString}
                setFilterString={setFilterString}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
            />

            <div className="mx-full px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto overflow-hidden">
                        <table className="min-w-full">
                            {(filterString.length || filteredData.length
                                ? filteredData
                                : tableData
                            ).map((row, i) => (
                                <tbody key={i}>
                                    <tr>
                                        <td>
                                            <table className="bg-white min-w-full rounded-tl-lg rounded-tr-lg">
                                                <tbody>
                                                    <tr key={row.id}>
                                                        <td>
                                                            <table className="min-w-full ">
                                                                {tableHeaders
                                                                    .filter(
                                                                        (
                                                                            header
                                                                        ) =>
                                                                            header.showOnTable ===
                                                                            true
                                                                    )
                                                                    .map(
                                                                        (
                                                                            column,
                                                                            j
                                                                        ) => (
                                                                            <tbody
                                                                                key={
                                                                                    j
                                                                                }
                                                                            >
                                                                                <tr>
                                                                                    <td
                                                                                        className={`w-1/5 px-2 py-2 ${
                                                                                            j ===
                                                                                            0
                                                                                                ? `bg-gray-200 text-green-800 rounded-tl-lg font-bold text-md`
                                                                                                : `bg-white text-sm`
                                                                                        }`}
                                                                                    >
                                                                                        {tableHeaders[
                                                                                            j
                                                                                        ]
                                                                                            .value +
                                                                                            `:`}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`w-4/5 px-2 py-2 ${
                                                                                            j ===
                                                                                            0
                                                                                                ? `bg-gray-200 text-green-800 rounded-tr-lg text-md font-bold`
                                                                                                : `bg-white text-sm`
                                                                                        }`}
                                                                                    >
                                                                                        <div className="float-left">
                                                                                            {Array.isArray(
                                                                                                row
                                                                                                    .normalised[
                                                                                                    column
                                                                                                        .key
                                                                                                ]
                                                                                            )
                                                                                                ? row
                                                                                                      .normalised[
                                                                                                      column
                                                                                                          .key
                                                                                                  ]
                                                                                                      .length ===
                                                                                                  0
                                                                                                    ? `-`
                                                                                                    : row.normalised[
                                                                                                          column
                                                                                                              .key
                                                                                                      ].map(
                                                                                                          (
                                                                                                              val,
                                                                                                              k
                                                                                                          ) => (
                                                                                                              <div
                                                                                                                  key={
                                                                                                                      k
                                                                                                                  }
                                                                                                              >
                                                                                                                  {Array.isArray(
                                                                                                                      val
                                                                                                                  )
                                                                                                                      ? val.join(
                                                                                                                            ", "
                                                                                                                        )
                                                                                                                      : val}
                                                                                                              </div>
                                                                                                          )
                                                                                                      )
                                                                                                : row
                                                                                                      .normalised[
                                                                                                      column
                                                                                                          .key
                                                                                                  ] !==
                                                                                                      undefined &&
                                                                                                  row
                                                                                                      .normalised[
                                                                                                      column
                                                                                                          .key
                                                                                                  ]
                                                                                                      ?.length !==
                                                                                                      0
                                                                                                ? row
                                                                                                      .normalised[
                                                                                                      column
                                                                                                          .key
                                                                                                  ]
                                                                                                : `-`}
                                                                                        </div>
                                                                                        {j ===
                                                                                            0 &&
                                                                                            editable &&
                                                                                            !row?.closed && (
                                                                                                <>
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="p-1 mx-1 rounded-full text-gray-600 hover:text-gray-800 float-left"
                                                                                                    >
                                                                                                        <PencilIcon
                                                                                                            className="h-5 w-5"
                                                                                                            aria-hidden="true"
                                                                                                            onClick={() =>
                                                                                                                setEditableRow(
                                                                                                                    row
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </button>
                                                                                                    <Delete
                                                                                                        apiRoute={
                                                                                                            API_ROUTES
                                                                                                                .CONTENT
                                                                                                                .KPA_DETAIL
                                                                                                        }
                                                                                                        rowData={
                                                                                                            row
                                                                                                        }
                                                                                                        loadData={
                                                                                                            loadData
                                                                                                        }
                                                                                                        titleValue={
                                                                                                            "KPA"
                                                                                                        }
                                                                                                    />
                                                                                                </>
                                                                                            )}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )
                                                                    )}
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="bg-white min-w-full rounded-lg mb-5 text-sm">
                                                <thead className="bg-gray-100 text-left border-r border-l border-white">
                                                    <tr>
                                                        <th className="text-green-800 w-5/12 px-2 py-2 ">
                                                            <div className="bg-gray-200  p-2 rounded">
                                                                Indicators (
                                                                {
                                                                    row?.indicators?.filter(
                                                                        (
                                                                            indicator
                                                                        ) =>
                                                                            indicator.active ===
                                                                            true
                                                                    )?.length
                                                                }
                                                                ){" "}
                                                                <span
                                                                    onClick={() => {
                                                                        setKpa(
                                                                            row
                                                                        );
                                                                        setOpenAddEditIndicator(
                                                                            true
                                                                        );
                                                                    }}
                                                                    className={`brand-link`}
                                                                >
                                                                    Add
                                                                    Indicator
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className="w-2/12 px-2 py-2 font-normal">
                                                            <div className=" p-2 rounded">
                                                                Indicator Unit
                                                                Of Measurement
                                                                (UOM)
                                                            </div>
                                                        </th>
                                                        {GLOBAL.TERMS.map(
                                                            (year, x) => (
                                                                <th
                                                                    key={x}
                                                                    className="w-1/12 px-2 py-2 font-normal"
                                                                >
                                                                    <div className="p-2 rounded">
                                                                        {row?.indicators?.filter(
                                                                            (
                                                                                indicator
                                                                            ) =>
                                                                                indicator.active ===
                                                                                true
                                                                        )
                                                                            ?.length !==
                                                                            0 &&
                                                                        getYear(
                                                                            new Date()
                                                                        ) ===
                                                                            getYear(
                                                                                new Date(
                                                                                    term[
                                                                                        `year` +
                                                                                            year +
                                                                                            `_start_date`
                                                                                    ]
                                                                                )
                                                                            ) ? (
                                                                            <NavLink
                                                                                to={`${
                                                                                    PORTAL.IDP_KPIS_BY_KPA
                                                                                }/${
                                                                                    row.id
                                                                                }/${
                                                                                    term.id
                                                                                }/${getTermYearByIndex(
                                                                                    term,
                                                                                    year
                                                                                )}`}
                                                                                className={`${
                                                                                    getYear(
                                                                                        new Date()
                                                                                    ) ===
                                                                                    getYear(
                                                                                        new Date(
                                                                                            term[
                                                                                                `year` +
                                                                                                    year +
                                                                                                    `_start_date`
                                                                                            ]
                                                                                        )
                                                                                    )
                                                                                        ? `brand-link`
                                                                                        : `hover:underline`
                                                                                }`}
                                                                            >
                                                                                Year{" "}
                                                                                {
                                                                                    year
                                                                                }{" "}
                                                                                (
                                                                                {getTermYearByIndex(
                                                                                    term,
                                                                                    year
                                                                                )}

                                                                                )
                                                                            </NavLink>
                                                                        ) : (
                                                                            <>
                                                                                Year{" "}
                                                                                {
                                                                                    year
                                                                                }{" "}
                                                                                (
                                                                                {getTermYearByIndex(
                                                                                    term,
                                                                                    year
                                                                                )}

                                                                                )
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {row?.indicators
                                                        ?.filter(
                                                            (indicator) =>
                                                                indicator.active ===
                                                                true
                                                        )
                                                        .map((indicator, x) => (
                                                            <tr key={x}>
                                                                <td className="w-5/12 px-2 py-2">
                                                                    {
                                                                        indicator.indicator_name
                                                                    }

                                                                    <button
                                                                        type="button"
                                                                        className="p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                                    >
                                                                        <PencilIcon
                                                                            className="h-4 w-4"
                                                                            aria-hidden="true"
                                                                            onClick={() => {
                                                                                setKpa(
                                                                                    row
                                                                                );
                                                                                setEditableIndicator(
                                                                                    indicator
                                                                                );
                                                                            }}
                                                                        />
                                                                    </button>
                                                                    <Delete
                                                                        apiRoute={
                                                                            API_ROUTES
                                                                                .CONTENT
                                                                                .KPA_INDICATOR
                                                                        }
                                                                        rowData={
                                                                            indicator
                                                                        }
                                                                        loadData={
                                                                            loadData
                                                                        }
                                                                        titleValue={
                                                                            "Indicator"
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="w-2/12 px-2 py-2">
                                                                    {
                                                                        indicator
                                                                            .indicator_umo
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td className="w-1/12 px-2 py-2">
                                                                    {
                                                                        indicator.indicator_year1
                                                                    }
                                                                    {indicator.indicator_umo.name.toUpperCase() ===
                                                                    `PERCENTAGE`
                                                                        ? `%`
                                                                        : ""}
                                                                </td>
                                                                <td className="w-1/12 px-2 py-2">
                                                                    {
                                                                        indicator.indicator_year2
                                                                    }
                                                                    {indicator.indicator_umo.name.toUpperCase() ===
                                                                    `PERCENTAGE`
                                                                        ? `%`
                                                                        : ""}
                                                                </td>
                                                                <td className="w-1/12 px-2 py-2">
                                                                    {
                                                                        indicator.indicator_year3
                                                                    }
                                                                    {indicator.indicator_umo.name.toUpperCase() ===
                                                                    `PERCENTAGE`
                                                                        ? `%`
                                                                        : ""}
                                                                </td>
                                                                <td className="w-1/12 px-2 py-2">
                                                                    {
                                                                        indicator.indicator_year4
                                                                    }
                                                                    {indicator.indicator_umo.name.toUpperCase() ===
                                                                    `PERCENTAGE`
                                                                        ? `%`
                                                                        : ""}
                                                                </td>
                                                                <td className="w-1/12 px-2 py-2">
                                                                    {
                                                                        indicator.indicator_year5
                                                                    }
                                                                    {indicator.indicator_umo.name.toUpperCase() ===
                                                                    `PERCENTAGE`
                                                                        ? `%`
                                                                        : ""}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>

                        {/* Pagination */}
                        <nav
                            className="w-full bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                            aria-label="Pagination"
                        >
                            <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                    Showing{" "}
                                    <span className="font-medium">
                                        {filterString.length ||
                                        filteredData.length
                                            ? filteredData.length
                                            : tableData.length}
                                    </span>{" "}
                                    of{" "}
                                    <span className="font-medium">
                                        {tableData.length}
                                    </span>{" "}
                                    results
                                </p>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}
