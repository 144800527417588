import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { ApiConsumer } from "../../api/ApiConsumer";

export function StackedLine({
    url = "",
    height = "250",
    name = "",
    title = "",
}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const options = {
        title: "",
        legend: { position: "bottom" },
        chartArea: { width: "75%" },
        isStacked: true,
        hAxis: {
            title: "",
            minValue: 0,
        },
        vAxis: {
            title: "",
        },
        series: {
            0: { color: "#0F9017" },
            1: { color: "#3366CC" },
        },
    };

    useEffect(() => {
        setLoading(true);
        ApiConsumer.get(url)
            .then((res) => {
                // let types = [];
                // res.data.types.map((x) => types.push(x.name));
                // let countries = [];
                // res.data.results.map((x) =>
                //     countries.indexOf(x.kpa) === -1
                //         ? countries.push(x.kpa)
                //         : null
                // );
                //
                // let final = [];
                // countries.slice(0, 5).map((c) => {
                //     let temp = [];
                //     temp.push(c);
                //     types.map((t) => {
                //         let x = res.data.results.filter(
                //             (x) => x.kpa === c && x.name === t
                //         );
                //         if (x.length === 0) {
                //             temp.push(0);
                //         } else {
                //             temp.push(x[0].count);
                //         }
                //     });
                //     final.push(temp);
                // });
                // const test = [["Kpa", ...types], ...final];
                setData(res.data);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    }, [url]);

    return (
        <>
            <div className="min-w-0 flex-1">
                <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
                    <h3 className="bg-white p-2 text-center">{title}</h3>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height={`${height}px`}
                        data={data}
                        options={options}
                        className={"shadow-2xl rounded"}
                    />
                </div>
            </div>
        </>
    );
}
