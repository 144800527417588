import { useEffect, useState, useContext } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import { sub, add, compareDesc, isAfter } from "date-fns";
import { ApiConsumer } from "../api/ApiConsumer";
import { NavLink } from "react-router-dom";
import { GLOBAL } from "../constants/global";
import { API_ROUTES } from "../routes/api";
import { PORTAL } from "../routes/portal";
import { useTextFormatHook } from "../hooks/text-formatter";
import { useDataLoaderHook } from "../hooks/data-loader";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppLoader } from "../components/loader/app-loader";
import { AppLoaderContext } from "../contexts/app-loader";

export default function SectionReports() {
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    let { loadMultiple } = useDataLoaderHook();
    const [formDetails, setFormDetails] = useState({});
    const [kpas, setKpas] = useState([]);
    const [programmeTypes, setProgrammeTypes] = useState([]);
    const [responsibleManagers, setResponsibleManagers] = useState([]);
    const [programmesReport, setProgrammesReport] = useState([]);

    const addProgrammeLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.PROJECTS_BY_SECTION}/${row.id}`}
                        className={`brand-link`}
                    >
                        {row.normalised.name}
                    </NavLink>
                    <br />
                    <span className="text-xs">
                        ({row.total_projects}{" "}
                        {row.total_projects === 1 ? `project` : `projects`})
                    </span>
                </>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }

        ApiConsumer.post(API_ROUTES.REPORT.SECTIONS, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data = res.data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );
                //Filter by status
                res.data.map((row, i) => {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.overall_progress < 100
                    )
                        res.data[i]["status"] = "OVERDUE".toLowerCase();
                    else
                        res.data[i]["status"] = GLOBAL.SLIDER.filter((x) =>
                            row.overall_progress <= 100
                                ? x.MIN <= row.overall_progress &&
                                  x.MAX >= row.overall_progress
                                : x.MAX > 100
                        )[0].NAME.toLowerCase();
                });
                if (
                    formDetails.programme_status !== undefined &&
                    formDetails.programme_status.length !== 0
                )
                    res.data = res.data.filter(
                        (report) =>
                            formDetails.programme_status.indexOf(
                                report.status
                            ) !== -1
                    );

                let normalisedProgrammeData = normaliseTableData(
                    GLOBAL.SECTION_HEADERS,
                    res.data
                );
                setProgrammesReport(
                    addProgrammeLinksToTableData(normalisedProgrammeData)
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onProgrammeDateChange = (dates) => {
        const [start, end] = dates;
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_end_date`]: end,
        }));
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_start_date`]: sub(new Date(), { years: 1 }),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    useEffect(() => {
        loadMultiple(API_ROUTES.CONTENT.KPA, setKpas);
        loadMultiple(API_ROUTES.SECTION.TYPES, setProgrammeTypes);
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let userOptions = res.data.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setResponsibleManagers(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });

        return () => {
            setProgrammeTypes([]);
            setResponsibleManagers([]);
        };
    }, []);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpas`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_types`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_status`]: [],
        }));
        setProgrammesReport([]);
    };

    return (
        <>
            <PageHeader pageHeaderName={`Section/Group Reports`} />

            <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                <div className="grid grid-cols-12 space-x-4">
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="kpas">
                            KPAs:
                        </label>
                        <Select
                            id="kpas"
                            name="kpas"
                            placeholder="All KPAs"
                            isMulti
                            options={kpas}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`kpas`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(kpas).length !== 0 &&
                                formDetails.kpas !== undefined
                                    ? formDetails?.kpas?.map(
                                          (selectedOption) => {
                                              return kpas[
                                                  kpas.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="programme_types">
                            Section/Group types:
                        </label>
                        <Select
                            id="programme_types"
                            name="programme_types"
                            placeholder="All types"
                            isMulti
                            options={programmeTypes}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`programme_types`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(programmeTypes).length !== 0 &&
                                formDetails.programme_types !== undefined
                                    ? formDetails?.programme_types?.map(
                                          (selectedOption) => {
                                              return programmeTypes[
                                                  programmeTypes.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>

                    <div className="col-span-3">
                        <label
                            className="text-sm"
                            htmlFor="responsible_managers"
                        >
                            Responsible Managers(s):
                        </label>
                        <Select
                            id="responsible_managers"
                            name="responsible_managers"
                            placeholder="All users"
                            isMulti
                            options={responsibleManagers}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`responsible_managers`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(responsibleManagers).length !== 0 &&
                                formDetails.responsible_managers !== undefined
                                    ? formDetails?.responsible_managers?.map(
                                          (selectedOption) => {
                                              return responsibleManagers[
                                                  responsibleManagers.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>

                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="programme_status">
                            Section/Group status:
                        </label>
                        <Select
                            id="programme_status"
                            name="programme_status"
                            placeholder="All statuses"
                            isMulti
                            options={GLOBAL.STATUS}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`programme_status`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(GLOBAL.STATUS).length !== 0 &&
                                formDetails.programme_status !== undefined
                                    ? formDetails?.programme_status?.map(
                                          (selectedOption) => {
                                              return GLOBAL.STATUS[
                                                  GLOBAL.STATUS.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="relative py-2">
                <AppLoader
                    pageContent={
                        <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 text-lg leading-6 text-gray-900 text-left items-center">
                            <span className="min-w-0 flex-1 items-center font-medium">
                                Section/Group Results ({programmesReport.length}
                                )
                                <br />
                                <span
                                    className="brand-link text-sm font-normal"
                                    onClick={() => clearSearch()}
                                >
                                    Clear all search parameters
                                </span>
                            </span>

                            <div className="col-span-3">
                                <label
                                    className="text-sm float-left"
                                    htmlFor="date_range"
                                >
                                    Date range:
                                </label>
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.programme_start_date}
                                    onChange={(event) =>
                                        onProgrammeDateChange(event)
                                    }
                                    className="form-field"
                                    startDate={formDetails.programme_start_date}
                                    endDate={formDetails.programme_end_date}
                                    selectsRange
                                />
                            </div>
                            <button
                                type="button"
                                className="button flex-shrink-0 ml-2 mt-4"
                                onClick={() => searchReport()}
                            >
                                <div
                                    className="flex items-center pointer-events-none mr-1"
                                    aria-hidden="true"
                                >
                                    <MagnifyingGlassIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                Search
                            </button>
                        </h2>
                    }
                />
            </div>
            <Table
                tableTitle={`Section/Group Report Results`}
                tableHeaders={GLOBAL.SECTION_HEADERS}
                tableData={programmesReport}
                fontSize="text-xs"
            />
        </>
    );
}
