import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AddEditKpiIndicator from "../components/forms/add-edit-kpi-indicator";
import Modal from "../components/modal";
import { PORTAL } from "../routes/portal";
import { GLOBAL } from "../constants/global";
import AddEditAuditFindings from "../components/forms/add-edit-audit-findings";
import ViewAuditFindings from "../components/forms/view-audit-findings";

export default function TableKPI({
    type,
    year,
    yearIndex,
    kpi,
    tableTitle = "",
    tableHeaders = [],
    tableData = [],
    editable = false,
    setEditableRow = {},
    loadData = {},
}) {
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [openAddEditIndicators, setOpenAddEditIndicators] = useState(false);
    const [editableIndicators, setEditableIndicators] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [openAddEditAuditFindings, setOpenAddEditAuditFindings] =
        useState(false);

    const [openViewAuditFindings, setOpenViewAuditFindings] = useState(false);

    const [editableAuditFindings, setEditableAuditFindings] = useState({});

    useEffect(() => {
        loadData();
        return () => {
            setOpenAddEditIndicators(false);
            setEditableIndicators({});
        };
    }, []);

    const onCloseAddEditIndicators = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableIndicators({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditIndicators(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableIndicators).length === 0
            ? setOpenAddEditIndicators(false)
            : setOpenAddEditIndicators(true);
    }, [editableIndicators]);

    useEffect(() => {
        loadData();
        return () => {
            setOpenAddEditIndicators(false);
            setEditableIndicators({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditAuditFindings = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableAuditFindings({});
        }
        setOpenAddEditAuditFindings(modalStatus);
    };

    /*  useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableAuditFindings).length === 0
            ? setOpenAddEditAuditFindings(false)
            : setOpenAddEditAuditFindings(true);
    }, [editableAuditFindings]); */

    const onCloseViewAuditFindings = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableAuditFindings({});
        }
        setOpenViewAuditFindings(modalStatus);
    };

    /* useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableAuditFindings).length === 0
            ? setOpenViewAuditFindings(false)
            : setOpenViewAuditFindings(true);
    }, [editableAuditFindings]); */

    return (
        <>
            <Modal
                modalHeaderTag={
                    `INDICATOR: ` + editableAuditFindings?.indicator_name
                }
                modalHeader={`Add KPI Audit Finding`}
                modalContent={
                    <AddEditAuditFindings
                        kpi={kpi}
                        setOpen={onCloseAddEditAuditFindings}
                        editableRow={editableAuditFindings}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditAuditFindings}
                setOpen={onCloseAddEditAuditFindings}
            />

            <Modal
                modalHeaderTag={
                    `INDICATOR: ` + editableAuditFindings?.indicator_name
                }
                modalHeader={`View KPI Audit Findings`}
                modalContent={
                    <ViewAuditFindings
                        kpi={kpi}
                        setOpen={onCloseViewAuditFindings}
                        editableRow={editableAuditFindings}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openViewAuditFindings}
                setOpen={onCloseViewAuditFindings}
            />

            <Modal
                modalHeaderTag={`YEAR ${yearIndex} (${year})`}
                modalHeader={
                    `IDP Targets for:<br />` +
                    editableIndicators?.indicator_name
                }
                modalContent={
                    <AddEditKpiIndicator
                        year={year}
                        yearIndex={yearIndex}
                        kpa={kpi?.kpa}
                        setOpen={onCloseAddEditIndicators}
                        editableRow={editableIndicators}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditIndicators}
                setOpen={onCloseAddEditIndicators}
            />

            <div className="mx-full p-4 sm:p-6 lg:p-8">
                <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto overflow-hidden">
                        <table className="min-w-full">
                            {(filterString.length || filteredData.length
                                ? filteredData
                                : tableData
                            ).map((row, i) => (
                                <tbody key={i}>
                                    <tr>
                                        <td>
                                            <table className="bg-white min-w-full rounded-tr-lg rounded-tl-lg">
                                                <tbody>
                                                    <tr key={row.id}>
                                                        <td>
                                                            <table className="min-w-full ">
                                                                {tableHeaders
                                                                    .filter(
                                                                        (
                                                                            header
                                                                        ) =>
                                                                            header.showOnTable ===
                                                                            true
                                                                    )
                                                                    .map(
                                                                        (
                                                                            column,
                                                                            j
                                                                        ) => (
                                                                            <tbody
                                                                                key={
                                                                                    j
                                                                                }
                                                                            >
                                                                                <tr>
                                                                                    <td
                                                                                        className={`w-1/5 px-2 py-2 ${
                                                                                            j ===
                                                                                            0
                                                                                                ? `bg-gray-200 text-green-800 rounded-tl-lg font-bold text-md`
                                                                                                : `bg-white rounded-bl-lg text-sm`
                                                                                        }`}
                                                                                    >
                                                                                        {tableHeaders[
                                                                                            j
                                                                                        ]
                                                                                            .value +
                                                                                            `:`}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`w-4/5 px-2 py-2 ${
                                                                                            j ===
                                                                                            0
                                                                                                ? `bg-gray-200 text-green-800 rounded-tr-lg text-md font-bold`
                                                                                                : `bg-white rounded-br-lg text-sm`
                                                                                        }`}
                                                                                    >
                                                                                        {Array.isArray(
                                                                                            row
                                                                                                .normalised[
                                                                                                column
                                                                                                    .key
                                                                                            ]
                                                                                        )
                                                                                            ? row
                                                                                                  .normalised[
                                                                                                  column
                                                                                                      .key
                                                                                              ]
                                                                                                  .length ===
                                                                                              0
                                                                                                ? `-`
                                                                                                : row.normalised[
                                                                                                      column
                                                                                                          .key
                                                                                                  ].map(
                                                                                                      (
                                                                                                          val,
                                                                                                          k
                                                                                                      ) => (
                                                                                                          <div
                                                                                                              key={
                                                                                                                  k
                                                                                                              }
                                                                                                          >
                                                                                                              {Array.isArray(
                                                                                                                  val
                                                                                                              )
                                                                                                                  ? val.join(
                                                                                                                        ", "
                                                                                                                    )
                                                                                                                  : val}
                                                                                                          </div>
                                                                                                      )
                                                                                                  )
                                                                                            : row
                                                                                                  .normalised[
                                                                                                  column
                                                                                                      .key
                                                                                              ] !==
                                                                                                  undefined &&
                                                                                              row
                                                                                                  .normalised[
                                                                                                  column
                                                                                                      .key
                                                                                              ]
                                                                                                  ?.length !==
                                                                                                  0
                                                                                            ? row
                                                                                                  .normalised[
                                                                                                  column
                                                                                                      .key
                                                                                              ]
                                                                                            : `-`}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )
                                                                    )}
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="bg-white min-w-full rounded-br-lg rounded-bl-lg mb-5 text-sm">
                                                <thead className="bg-gray-100 text-left border-r border-l border-white">
                                                    <tr>
                                                        <th className="text-green-800 w-4/12 px-2 py-2">
                                                            <div className="bg-gray-200 p-2 rounded">
                                                                {type.toUpperCase()}{" "}
                                                                Target Indicator
                                                                (KPI)
                                                            </div>
                                                        </th>

                                                        <th className="w-1/12 px-2 py-2 font-semibold">
                                                            Planning
                                                            <br />
                                                            Level
                                                        </th>
                                                        <th className="w-2/12 px-2 py-2 font-semibold">
                                                            Measurable
                                                            <br />
                                                            Objective
                                                        </th>
                                                        <th className="w-2/12 px-2 py-2 font-semibold">
                                                            Evidence
                                                        </th>
                                                        <th className="w-1/12 px-2 py-2 font-semibold">
                                                            UOM
                                                        </th>
                                                        <th className="w-1/12 px-2 py-2 font-semibold">
                                                            Baseline
                                                        </th>
                                                        <th className="w-1/12 px-2 py-2 font-semibold">
                                                            Annual
                                                            <br />
                                                            Target
                                                        </th>
                                                        {GLOBAL.QUARTERS.map(
                                                            (quarter, x) => (
                                                                <th
                                                                    key={x}
                                                                    className="w-0.5 px-2 py-2 font-semibold"
                                                                >
                                                                    Q{quarter}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {row?.indicators
                                                        ?.filter(
                                                            (indicator) =>
                                                                indicator.active ===
                                                                true
                                                        )
                                                        ?.map(
                                                            (indicator, x) => (
                                                                <tr
                                                                    key={x}
                                                                    className="border-t"
                                                                >
                                                                    <td className="w-4/12 px-2 py-2">
                                                                        {type ===
                                                                        "sdbip" ? (
                                                                            <NavLink
                                                                                to={`${PORTAL.PROJECTS}/${year}/${kpi?.kpa?.id}/${indicator?.id}/`}
                                                                                className={
                                                                                    "brand-link"
                                                                                }
                                                                            >
                                                                                {
                                                                                    indicator.indicator_name
                                                                                }
                                                                            </NavLink>
                                                                        ) : (
                                                                            indicator.indicator_name
                                                                        )}
                                                                        {type ===
                                                                            "idp" && (
                                                                            <>
                                                                                <br />
                                                                                <span
                                                                                    onClick={() => {
                                                                                        setEditableIndicators(
                                                                                            indicator
                                                                                        );
                                                                                        setOpenAddEditIndicators(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                    className={`brand-link`}
                                                                                >
                                                                                    Update
                                                                                    KPI
                                                                                    Targets
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                        {type ===
                                                                            "sdbip" && (
                                                                            <>
                                                                                <div className="clear-both"></div>
                                                                                <button
                                                                                    target="_blank"
                                                                                    className={`button-default text-xs float-left mr-2 mt-2`}
                                                                                    onClick={() => {
                                                                                        setEditableAuditFindings(
                                                                                            indicator
                                                                                        );
                                                                                        setOpenAddEditAuditFindings(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Add
                                                                                    Finding
                                                                                </button>
                                                                                <button
                                                                                    target="_blank"
                                                                                    className={`button-default text-xs float-left mr-2 mt-2`}
                                                                                    onClick={() => {
                                                                                        setEditableAuditFindings(
                                                                                            indicator
                                                                                        );
                                                                                        setOpenViewAuditFindings(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    View
                                                                                    Findings
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    <td className="w-1/12 px-2 py-2">
                                                                        {
                                                                            indicator.planning_level
                                                                        }
                                                                    </td>
                                                                    <td className="w-2/12 px-2 py-2">
                                                                        {
                                                                            indicator.measurable_objective
                                                                        }
                                                                    </td>
                                                                    <td className="w-2/12 px-2 py-2">
                                                                        {
                                                                            indicator.evidence
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/12 px-2 py-2">
                                                                        {
                                                                            indicator
                                                                                ?.indicator_umo
                                                                                ?.name
                                                                        }
                                                                    </td>
                                                                    <td className="w-1/12 px-2 py-2">
                                                                        {
                                                                            indicator?.baseline
                                                                        }
                                                                        {indicator?.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </td>
                                                                    <td className="w-1/12 px-2 py-2">
                                                                        {
                                                                            indicator[
                                                                                `indicator_year` +
                                                                                    yearIndex
                                                                            ]
                                                                        }
                                                                        {indicator?.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </td>
                                                                    <td className="w-0.5 px-2 py-2">
                                                                        {
                                                                            kpi.indicators[0].indicators.filter(
                                                                                (
                                                                                    q
                                                                                ) =>
                                                                                    q.id ===
                                                                                    indicator.id
                                                                            )[0]
                                                                                ?.q1
                                                                        }
                                                                        {indicator?.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </td>
                                                                    <td className="w-0.5 px-2 py-2">
                                                                        {
                                                                            kpi.indicators[0].indicators.filter(
                                                                                (
                                                                                    q
                                                                                ) =>
                                                                                    q.id ===
                                                                                    indicator.id
                                                                            )[0]
                                                                                ?.q2
                                                                        }
                                                                        {indicator?.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </td>
                                                                    <td className="w-0.5 px-2 py-2">
                                                                        {
                                                                            kpi.indicators[0].indicators.filter(
                                                                                (
                                                                                    q
                                                                                ) =>
                                                                                    q.id ===
                                                                                    indicator.id
                                                                            )[0]
                                                                                ?.q3
                                                                        }
                                                                        {indicator?.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </td>
                                                                    <td className="w-0.5 px-2 py-2">
                                                                        {
                                                                            kpi.indicators[0].indicators.filter(
                                                                                (
                                                                                    q
                                                                                ) =>
                                                                                    q.id ===
                                                                                    indicator.id
                                                                            )[0]
                                                                                ?.q4
                                                                        }
                                                                        {indicator?.indicator_umo?.name.toUpperCase() ===
                                                                        `PERCENTAGE`
                                                                            ? `%`
                                                                            : ""}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
