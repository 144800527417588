import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "../hooks/form-validation";
import Alert from "../components/alert";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "../api/ApiConsumer";
import { API_ROUTES } from "../routes/api";
import { format, compareDesc } from "date-fns";
import { AuthenticationContext } from "../contexts/authentication";
import Delete from "../components/delete";
import ModalUserDetails from "../components/modal-user-details";
import Modal from "../components/modal";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { AppLoaderContext } from "../contexts/app-loader";
import DownloadAsPdf from "../components/share/download-pdf";
import { GLOBAL } from "../constants/global";
import { useTextFormatHook } from "../hooks/text-formatter";

export default function Comments({ type, route, obj, quarter = null }) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [comments, setComments] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    let { normaliseTableData } = useTextFormatHook();

    const formFields = [
        { name: "quarter", display: "quarter" },
        { name: "comment", display: "comment" },
    ];

    const validateAddComment = () => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            let formData = new FormData();
            for (const key in formDetails) {
                formData.append(key, formDetails[key]);
            }
            formData.append(
                "comment_attachment",
                formDetails.comment_attachment
            );

            ApiConsumer.post(API_ROUTES.COMMENTS.COMMENTS, formData)
                .then((res) => {
                    loadComments();
                    setFormSubmitted(true);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`quarter`]: quarter !== null ? quarter : "selected",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`comment`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`comment_attachment`]: "",
                    }));
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const loadComments = () => {
        ApiConsumer.get(route(obj.id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                console.log(res.data);
                setComments(
                    quarter !== null
                        ? res.data.filter(
                              (comment) => parseInt(comment.quarter) === quarter
                          )
                        : res.data
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };

    useEffect(() => {
        loadComments();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: obj.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`quarter`]: quarter !== null ? quarter : "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`comment`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`comment_attachment`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`posted_by`]: currentUser.id,
        }));

        return () => {
            setComments([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [obj.id, currentUser.id]);

    const CommentsTableHeaders = [
        { key: "quarter", value: "Quarter", showOnTable: true, showOnPrint: true },
        {
            key: "comment",
            value: "Comment",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "comment_attachment",
            value: "Comment Attachment",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "posted_by",
            value: "Posted By",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "created",
            value: "Posted On",
            showOnTable: true,
            showOnPrint: true,
        }];

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="">
                <div className={`${type!=='task' ? "pb-5" : "p-5"}`}>
                    <h2
                        id="notes-title"
                        className="text-lg font-medium text-gray-900"
                    >
                        <strong>
                            {quarter !== null ? "Quarter " + quarter + " " : ""}{" "}
                            Comments ({comments.length})
                        </strong>
                    </h2>
                </div>

                <div className={`bg-gray-100 px-4 py-6 sm:px-6`}>
                    <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                            <span className="icon-transparent p-2 bg-orange-500 text-orange-100 hover:text-orange-100">
                                {currentUser.name.charAt(0).toUpperCase() +
                                    currentUser.surname.charAt(0).toUpperCase()}
                            </span>
                        </div>
                        <div className="min-w-0 flex-1">
                            <form
                                className="space-y-1 relative"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    validateAddComment();
                                }}
                            >
                                <div>
                                    <label
                                        htmlFor="comment"
                                        className="sr-only"
                                    >
                                        Post a comment
                                    </label>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-4 pb-1">
                                            <select
                                                id="quarter"
                                                name="quarter"
                                                autoComplete="quarter"
                                                className={`${
                                                    invalidFormDetails.quarter
                                                        ? `border-red-600`
                                                        : `border-gray-300`
                                                }`}
                                                onChange={(event) => {
                                                    setFormDetails(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            [`quarter`]:
                                                                event.target
                                                                    .value,
                                                        })
                                                    );
                                                }}
                                                value={
                                                    formDetails.quarter
                                                        ? formDetails.quarter
                                                        : "selected"
                                                }
                                                disabled={
                                                    quarter !== null
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option value="selected">
                                                    Select Quarter
                                                </option>
                                                <option value="1">
                                                    Quarter 1
                                                </option>
                                                <option value="2">
                                                    Quarter 2
                                                </option>
                                                <option value="3">
                                                    Quarter 3
                                                </option>
                                                <option value="4">
                                                    Quarter 4
                                                </option>
                                                <option value="0">
                                                    Annual/Overall
                                                </option>
                                            </select>
                                        </div>

                                        <div className="col-span-8 pb-1"></div>
                                    </div>
                                    <textarea
                                        id="comment"
                                        name="comment"
                                        rows="3"
                                        placeholder="Add a comment"
                                        autoComplete="comment"
                                        className={`form-field ${
                                            invalidFormDetails.comment
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`comment`]: event.target.value,
                                            }));
                                        }}
                                        value={
                                            formDetails.comment
                                                ? formDetails.comment
                                                : ""
                                        }
                                    ></textarea>
                                    <div className="flex space-x-2 mt-1">
                                        <PaperClipIcon
                                            className="h-5 w-5 float-left mt-3"
                                            aria-hidden="true"
                                        />
                                        <input
                                            id="comment_attachment"
                                            name="comment_attachment"
                                            autoComplete="comment_attachment"
                                            className={`form-field ${
                                                invalidFormDetails.comment_attachment
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`comment_attachment`]:
                                                        event.target.files[0],
                                                }));
                                                setFormSubmitted(false);
                                            }}
                                            //value={formDetails.comment_attachment}
                                            type="file"
                                        />
                                    </div>
                                </div>
                                {formError.length !== 0 && (
                                    <div className="col-span-12 form-error text-right">
                                        {formError}
                                    </div>
                                )}
                                {formSubmitted && (
                                    <Alert
                                        type={`success`}
                                        message={`Your comment has been successfully posted.`}
                                    />
                                )}
                                <div className="mt-2 float-right">
                                    <button type="submit" className="button">
                                        Post Comment
                                    </button>
                                </div>
                                <div className="clear-both"></div>
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    className={`${
                        type === "task" ? "px-4 py-6 sm:px-6" : "py-6"
                    }`}
                >

                    {comments.length ? <>
                        <div className="text-center mb-4"><DownloadAsPdf
                            tableTitle={`Project Comments:`}
                            tableHeaders={CommentsTableHeaders}
                            tableData={normaliseTableData(CommentsTableHeaders, comments)}
                            tableDataResult={obj?.name}
                            printType={GLOBAL.PRINT.TABLE}
                            filename={`Project Comments: ${obj?.name}`}
                            downloadIconText={"Download Comments"}
                        />
                        </div>
                        <div className="clear-both"></div>
                        </>
                      : null
                    }

                    <ul className="space-y-8">
                        {comments.length
                            ? comments.map((comment, i) => (
                                  <li key={i}>
                                      <div className="flex space-x-3">
                                          <div className="flex-shrink-0">
                                              <span className="icon-transparent p-2 bg-orange-500 text-orange-100 hover:text-orange-100">
                                                  {comment.posted_by.first_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      comment.posted_by.last_name
                                                          .charAt(0)
                                                          .toUpperCase()}
                                              </span>
                                              {comment.posted_by.id ===
                                                  currentUser.id && (
                                                  <Delete
                                                      apiRoute={
                                                          API_ROUTES.COMMENTS
                                                              .COMMENT_DETAIL
                                                      }
                                                      rowData={comment}
                                                      loadData={loadComments}
                                                      titleValue={"Comment"}
                                                  />
                                              )}
                                          </div>
                                          <div>
                                              <div className="font-medium">
                                                  <span
                                                      className="hover:underline cursor-pointer"
                                                      onClick={() => {
                                                          setUserDetailsRow(
                                                              comment.posted_by
                                                          );
                                                          setOpenUserDetails(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      {comment.posted_by
                                                          .first_name +
                                                          " " +
                                                          comment.posted_by
                                                              .last_name}
                                                  </span>
                                                  <span className=" font-normal text-sm ml-1">
                                                      (For{" "}
                                                      {parseInt(
                                                          comment.quarter
                                                      ) === 0
                                                          ? `Annual/Overall`
                                                          : `Quarter ` +
                                                            comment.quarter}
                                                      )
                                                  </span>
                                              </div>
                                              <div>
                                                  <span className="text-gray-400 text-sm">
                                                      {format(
                                                          new Date(
                                                              comment.created
                                                          ),
                                                          "dd MMM yyyy HH:mm:ss"
                                                      )}
                                                  </span>
                                              </div>
                                              <div className="mt-1 text-gray-700 text-sm">
                                                  <p>{comment.comment}</p>

                                                  {comment?.comment_attachment && (
                                                      <p className="text-sm">
                                                          <PaperClipIcon
                                                              className="h-4 w-4 inline"
                                                              aria-hidden="true"
                                                          />

                                                          <a
                                                              href={
                                                                  comment?.comment_attachment
                                                              }
                                                              target="_blank"
                                                              className="brand-link truncate inline"
                                                          >
                                                              View Attachment
                                                          </a>
                                                      </p>
                                                  )}
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              ))
                            : "There are no comments posted."}
                    </ul>
                </div>
            </div>
        </>
    );
}
