import { Routes, Route, Outlet } from "react-router-dom";
import { PORTAL } from "../routes/portal";
import Reports from "../pages/reports";
import SectionReports from "../pages/section-reports";
import ProjectReports from "../pages/project-reports";
import TaskReports from "../pages/task-reports";

export default function ReportRoutes() {
    return (
        <>
            <Routes>
                <Route index element={<Reports />} />
                <Route
                    path={PORTAL.SECTION_REPORTS.replace(PORTAL.REPORTS, "")}
                    element={<SectionReports />}
                />
                <Route
                    path={PORTAL.PROJECT_REPORTS.replace(PORTAL.REPORTS, "")}
                    element={<ProjectReports />}
                />
                <Route
                    path={PORTAL.TASK_REPORTS.replace(PORTAL.REPORTS, "")}
                    element={<TaskReports />}
                />
            </Routes>
            <Outlet />
        </>
    );
}
