import { useEffect, useState } from 'react'
import { ApiConsumer } from '../api/ApiConsumer'
import { API_ROUTES } from '../routes/api'
import { GLOBAL } from '../constants/global'
import { compareDesc, format } from 'date-fns'
import PageHeader from '../components/page-header'
import Table from '../components/table'
import { useTextFormatHook } from '../hooks/text-formatter'
import { PORTAL } from '../routes/portal'
import { APP_ID } from '../config'
import { Navigate } from 'react-router-dom'
import ModalUserDetails from '../components/modal-user-details'
import Modal from '../components/modal'
import * as Sentry from '@sentry/react'

export default function Activity() {
  let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID))
  let { normaliseTableData } = useTextFormatHook()
  const [activity, setActivity] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [userDetailsRow, setUserDetailsRow] = useState({})
  const [openUserDetails, setOpenUserDetails] = useState(false)
  const [editableRow, setEditableRow] = useState({})
  const pageHeaderButtons = []

  const tableHeaders = [
    {
      key: 'name',
      value: 'User',
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: 'activity',
      value: 'Activity',
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: 'created',
      value: 'Date',
      showOnTable: true,
      showOnPrint: true,
    },
  ]

  const addLinksToTableData = (tableData) => {
    tableData.forEach((row) => {
      row.normalised.name = (
        <span
          className={`brand-link`}
          onClick={() => {
            setUserDetailsRow(row.user)
            setOpenUserDetails(true)
          }}
        >
          {row.user.first_name + ' ' + row.user.last_name}
        </span>
      )
      row.name = row.user.first_name + ' ' + row.user.last_name
      row.normalised.created = format(
        new Date(row.created),
        'dd MMM yyyy HH:ii:ss',
      )
    })
    return tableData
  }

  const loadData = () => {
    ApiConsumer.get(API_ROUTES.USERS.ACTIVITY)
      .then((res) => {
        res.data.sort((a, b) =>
          compareDesc(new Date(a.created), new Date(b.created)),
        )
        let normalisedData = normaliseTableData(tableHeaders, res.data)
        setActivity(addLinksToTableData(normalisedData))
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
      .finally(() => {
        setLoaded(true)
      })
  }

  useEffect(() => {
    loadData()
    return () => {
      setActivity({})
      setLoaded(false)
    }
  }, [])

  if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
    return <Navigate to={PORTAL.LOGIN} />

  return (
    <>
      <Modal
        modalHeaderTag={''}
        modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
        modalContent={
          <ModalUserDetails
            setOpen={setOpenUserDetails}
            userDetailsRow={userDetailsRow}
          />
        }
        open={openUserDetails}
        setOpen={setOpenUserDetails}
      />

      <PageHeader
        pageHeaderName={`System Activity`}
        pageHeaderButtons={pageHeaderButtons}
      />

      {loaded && (
        <Table
          tableTitle={`System Activity`}
          tableHeaders={tableHeaders}
          tableData={activity}
          editable={false}
          setEditableRow={setEditableRow}
        />
      )}
    </>
  )
}
