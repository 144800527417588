import {useState, useEffect, useContext} from "react";
import {useFormValidation} from "../../hooks/form-validation";
import {API_ROUTES} from "../../routes/api";
import {ApiConsumer} from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import Alert from "../../components/alert";
import {AppLoaderContext} from "../../contexts/app-loader";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {format} from "date-fns";

export default function AddEditAuditAction({
                                               kpi,
                                               setOpen,
                                               editableRow,
                                               setRowUpdated,
                                               responsiblePerson,
                                               findingId,
                                           }) {
    const {setLoading} = useContext(AppLoaderContext);
    const {validFormDetails} = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        {name: "action_description", display: "Action Description"},
        {name: "target_completion_date", display: "Target Completion Date"},
        {name: "completion_status", display: "Completion Status"},
    ];

    const validateAddEditAuditAction = () => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            console.log(findingId)

            // Format date to "YYYY-MM-DD"
            const formattedData = {
                ...formDetails,
                internal_finding: findingId,
                target_completion_date: formDetails.target_completion_date
                    ? format(formDetails.target_completion_date, 'yyyy-MM-dd')
                    : null,
            };

            console.log(formattedData)

            const apiCall = !editableRow
                ? ApiConsumer.put(`${API_ROUTES.AUDIT.ACTIONS}/${editableRow.id}`, formattedData)
                : ApiConsumer.post(API_ROUTES.AUDIT.ACTIONS, formattedData);

            apiCall
                .then((res) => {
                    setRowUpdated(true);
                    setFormSubmitted(true);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    setFormError(err.toString());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        setFormDetails({
            action_description: editableRow?.action_description || "",
            responsible_person: editableRow?.responsible_person || null,
            target_completion_date: editableRow?.target_completion_date || null,
            completion_status: editableRow?.completion_status || "not_started",
        });

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditAuditAction();
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    <div className="space-y-2">
                        {/* Action Description */}
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="action_description"
                                >
                                    Action Description:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="action_description"
                                    name="action_description"
                                    autoComplete="action_description"
                                    className={`form-field ${
                                        invalidFormDetails.action_description
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            action_description: event.target.value,
                                        }));
                                    }}
                                    value={formDetails.action_description || ""}
                                ></textarea>
                            </div>
                        </div>

                        {/* Responsible Person */}
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="responsible_person"
                                >
                                    Responsible Person:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="responsible_person"
                                    name="responsible_person"
                                    options={responsiblePerson}
                                    classNamePrefix="select"
                                    className={`select ${
                                        invalidFormDetails.responsible_person
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(selectedOption) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            responsible_person: selectedOption.value,
                                        }));
                                    }}
                                    value={responsiblePerson.find(
                                        (option) =>
                                            option.value ===
                                            formDetails.responsible_person
                                    )}
                                />
                            </div>
                        </div>

                        {/* Target Completion Date */}
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="target_completion_date">
                                    Target Completion Date:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    selected={formDetails.target_completion_date}
                                    onChange={(date) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            target_completion_date: date,
                                        }));
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-field"
                                />
                            </div>
                        </div>

                        {/* Completion Status */}
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="completion_status">
                                    Completion Status:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="completion_status"
                                    name="completion_status"
                                    className={`form-field ${
                                        invalidFormDetails.completion_status
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            completion_status: event.target.value,
                                        }));
                                    }}
                                    value={formDetails.completion_status || "not_started"}
                                >
                                    <option value="not_started">Not Started</option>
                                    <option value="in_progress">In Progress</option>
                                    <option value="completed">Completed</option>
                                </select>
                            </div>
                        </div>

                        {formError && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {editableRow ? "Update Action" : "Add Action"}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            editableRow ? "updated" : "added"
                        } audit action`}
                    />
                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
