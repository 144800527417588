import {useEffect, useState, useContext} from "react";

import {NavLink} from "react-router-dom";

import Table from "../../components/table";
import {PORTAL} from "../../routes/portal";
import {ApiConsumer} from "../../api/ApiConsumer";
import {API_ROUTES} from "../../routes/api";
import PageHeader from "../../components/page-header";
import {Doughnut} from "../../components/charts/doughnut";
import {StackedLine} from "../../components/charts/stacked";
import {HorizontalBar} from "../../components/charts/hbar";
import {useTextFormatHook} from "../../hooks/text-formatter";
import {ActiveKpaContext} from "../../contexts/active-kpa";

export default function AuditingDashboard() {
    let {normaliseTableData} = useTextFormatHook();
    const [kpas, setKpa] = useState([]);
    const [loaded, setLoaded] = useState(false);
    let {setActiveKpa} = useContext(ActiveKpaContext);

    const tableHeaders = [
        {
            key: "description",
            value: "Finding",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "possible_impact",
            value: "Impact",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: true,
            showOnPrint: true,
        }
    ];


    const loadData = () => {
        ApiConsumer.get(API_ROUTES.AUDIT.FINDINGS)
            .then((res) => {
                res.data.sort((a, b) => a.created.localeCompare(b.created));
                console.log(res.data);
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setKpa(normalisedData);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setKpa({});
            setLoaded(false);
        };
    }, []);

    return (
        <>
            <PageHeader pageHeaderName={`DASHBOARD - Audit Findings`}/>
            <>
                <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <h3 className="mb-2">Overview</h3>
                    <div className="flex space-x-3">
                        <Doughnut
                            url={API_ROUTES.SECTION.GRAPHS.TYPE}
                            title={"Programmes by Type"}
                        />

                        <Doughnut
                            url={
                                API_ROUTES.SECTION.GRAPHS
                                    .PROJECT_ACTIVE_INACTIVE
                            }
                            title={"Active vs Complete Programmes"}
                        />

                        <StackedLine
                            url={
                                API_ROUTES.SECTION.GRAPHS
                                    .SECTION_BY_PROGRESS
                            }
                            title={"Section/Group by Progress"}
                        />
                    </div>
                </div>

                <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <h3 className="mb-2">Projects Overview</h3>
                    <div className="flex space-x-3">
                        <StackedLine
                            url={
                                API_ROUTES.SECTION.GRAPHS
                                    .PROJECT_BY_PROGRESS
                            }
                            title={"Projects by Progress"}
                        />

                        <HorizontalBar
                            url={
                                API_ROUTES.SECTION.GRAPHS
                                    .PROJECT_BY_TARGET
                            }
                            title={"Projects by Target vs Progress"}
                            name={"type"}
                            height={"250"}
                        />
                    </div>
                </div>

                <h2 className="flex-1 flex mx-full px-4 sm:px-6 lg:px-8 mt-8 text-lg leading-6 font-medium text-gray-900 text-left">
                    2024 Findings ({kpas?.length})
                </h2>
                <Table
                    tableTitle={`Audit Findings`}
                    tableHeaders={tableHeaders}
                    tableData={kpas}
                    editable={false}
                />
            </>
        </>
    );
}
