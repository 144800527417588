import { GLOBAL } from "../constants/global";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import DownloadAsPdf from "../components/share/download-pdf";
import DownloadAsExcel from "../components/share/download-excel";

export default function TableHeader({
    tableTitle,
    tableHeaders,
    tableData,
    filterString = "",
    setFilterString,
    filteredData = [],
    setFilteredData,
}) {
    let filterBy = [
        "name",
        "main_correspondent",
        "first_name",
        "last_name",
        "designation",
        "user_type",
        "type",
        "department",
    ];
    const filterTable = (enteredString) => {
        let filteredDataByEnteredString = filterBy.map((filter) => {
            return tableData.filter(
                (data) =>
                    (data[filter] !== undefined &&
                        typeof data[filter] === "string" &&
                        data[filter]
                            .toLowerCase()
                            .includes(enteredString.toLowerCase())) ||
                    (data.normalised[filter] !== undefined &&
                        typeof data.normalised[filter] === "string" &&
                        data.normalised[filter]
                            .toLowerCase()
                            .includes(enteredString.toLowerCase()))
            );
        });

        var mergedData = [].concat.apply([], filteredDataByEnteredString);
        //Group by "id" to avoid duplicates
        mergedData = mergedData.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );
        setFilterString(enteredString);
        setFilteredData(mergedData);
    };

    return (
        <>
            <div className="flex-1 flex mx-full px-4 sm:px-6 lg:px-8 my-5">
                <div className="flex-1 flex">
                    <form
                        className="w-full flex md:ml-0"
                        action="#"
                        method="GET"
                    >
                        <label htmlFor="search-field" className="sr-only">
                            Filter
                        </label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                            <div
                                className="absolute inset-y-0 left-2 flex items-center pointer-events-none"
                                aria-hidden="true"
                            >
                                <MagnifyingGlassIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                id="search-field"
                                name="search-field"
                                className="block w-full h-full pl-8 pr-3 py-3 rounded-md border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                                placeholder="Type here to filter"
                                type="search"
                                onChange={(event) =>
                                    filterTable(event.target.value)
                                }
                            />
                        </div>
                    </form>
                </div>
                <div className="ml-4 flex items-center md:ml-6">
                    <DownloadAsExcel
                        tableTitle={tableTitle}
                        tableHeaders={tableHeaders}
                        tableData={
                            filterString.length || filteredData.length
                                ? filteredData
                                : tableData
                        }
                        tableDataResult={`Showing ${
                            filterString.length || filteredData.length
                                ? filteredData.length
                                : tableData.length
                        } of ${tableData.length} results`}
                    />

                    <DownloadAsPdf
                        tableTitle={tableTitle}
                        tableHeaders={tableHeaders}
                        tableData={
                            filterString.length || filteredData.length
                                ? filteredData
                                : tableData
                        }
                        tableDataResult={`Showing ${
                            filterString.length || filteredData.length
                                ? filteredData.length
                                : tableData.length
                        } of ${tableData.length} results`}
                        printType={GLOBAL.PRINT.TABLE}
                    />
                </div>
            </div>
        </>
    );
}
