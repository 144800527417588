import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { API_ROUTES } from "../../routes/api";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import { GLOBAL } from "../../constants/global";
import "react-datepicker/dist/react-datepicker.css";
import { useDataLoaderHook } from "../../hooks/data-loader";
import Alert from "../../components/alert";
import { AppLoaderContext } from "../../contexts/app-loader";
import Select from "react-select";

export default function AddEditUser({
    userTypeParam,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    let { loadMultiple } = useDataLoaderHook();
    let selectedPermissions = [];
    const [userTypes, setUserTypes] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [managers, setManagers] = useState([]);
    const [programmePermissions, setProgrammePermissions] = useState([]);
    const [projectPermissions, setProjectPermissions] = useState([]);
    const [taskPermissions, setTaskPermissions] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [groupPermissions, setGroupPermissions] = useState([]);
    const [userPermissions, setUserPermissions] = useState([]);

    const formFields = [
        { name: "first_name", display: "first name" },
        { name: "last_name", display: "last name" },
        { name: "designation", display: "designation" },
        { name: "email", display: "email address" },
        { name: "user_type", display: "user type" },
        { name: "departments", display: "department / division" },
        { name: "managers", display: "reporting manager(s)" },
    ];

    const validateAddEditUser = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.USERS.USER_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.USERS.USERS, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (user) =>
                        [
                            GLOBAL.USER_TYPES.STAFF_MEMBER,
                            GLOBAL.USER_TYPES.ADMINISTRATOR,
                        ].indexOf(user.user_type.name) !== -1
                );

                if (Object.keys(editableRow).length !== 0)
                    users = users.filter((user) => editableRow.id !== user.id);

                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });

                setManagers(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.TYPES)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setUserTypes(
                    Object.keys(editableRow).length === 0
                        ? userTypeParam === "administrators"
                            ? res.data.filter(
                                  (type) =>
                                      type.name ===
                                      GLOBAL.USER_TYPES.ADMINISTRATOR
                              )
                            : res.data.filter(
                                  (type) =>
                                      type.name !==
                                      GLOBAL.USER_TYPES.ADMINISTRATOR
                              )
                        : res.data
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, [userTypeParam, editableRow]);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.PERPROJECTS_BY_GROUP)
            .then((res) => {
                setGroupPermissions(res.data);
                //Permission segments for disply
                setProgrammePermissions(
                    res.data.filter(
                        (permission) =>
                            permission.name === "Section/Group Permissions"
                    )[0].permissions
                );
                setProjectPermissions(
                    res.data.filter(
                        (permission) =>
                            permission.name === "Project Permissions"
                    )[0].permissions
                );
                setTaskPermissions(
                    res.data.filter(
                        (permission) => permission.name === "Task Permissions"
                    )[0].permissions
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    useEffect(() => {
        //Load existing user permissions
        if (Object.keys(editableRow).length !== 0) {
            ApiConsumer.get(
                API_ROUTES.USERS.PERPROJECTS_BY_USER(editableRow.id)
            )
                .then((res) => {
                    //Get IDs of permissions
                    selectedPermissions = res.data.individual_permissions.map(
                        (obj) => obj.id
                    );
                    setUserPermissions(selectedPermissions);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`permissions`]: selectedPermissions,
                    }));
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        }
    }, [editableRow]);

    const loadUserTypePermissions = (userTypeId) => {
        let userType = userTypes.filter(
            (userType) => userType.id === userTypeId
        )[0]?.name;

        selectedPermissions = groupPermissions
            .filter((permission) => permission.name === userType)[0]
            ?.permissions.map((obj) => obj.id);

        setUserPermissions(selectedPermissions);
        setFormDetails((prevState) => ({
            ...prevState,
            [`permissions`]: selectedPermissions,
        }));
    };

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`first_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`last_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`designation`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`email`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`contact_number`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`alternative_contact_number`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`user_type`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`managers`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`permissions`]: [],
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`user`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`first_name`]: editableRow.first_name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`last_name`]: editableRow.last_name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`designation`]: editableRow.designation,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`email`]: editableRow.email,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`contact_number`]: editableRow.contact_number,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`alternative_contact_number`]:
                    editableRow.alternative_contact_number,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`user_type`]: editableRow.user_type.id,
            }));
            let departments = [];
            editableRow.departments?.forEach((department, i) => {
                departments[i] = department.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`departments`]: departments,
            }));
            let managers = [];
            editableRow.managers?.forEach((manager, i) => {
                managers[i] = manager.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`managers`]: managers,
            }));
        }

        return () => {
            setUserTypes([]);
            setGroupPermissions([]);
            setProgrammePermissions([]);
            setProjectPermissions([]);
            setTaskPermissions([]);
            setUserPermissions([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditUser(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <span className="font-bold">Created:</span>{" "}
                            {format(
                                new Date(editableRow.created),
                                "dd MMM yyyy"
                            )}{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {format(
                                new Date(editableRow.updated),
                                "dd MMM yyyy"
                            )}{" "}
                        </div>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="first_name">
                                    First name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="first_name"
                                    name="first_name"
                                    type="text"
                                    autoComplete="first_name"
                                    className={`form-field ${
                                        invalidFormDetails.first_name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`first_name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.first_name
                                            ? formDetails.first_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="last_name">
                                    Last name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    autoComplete="last_name"
                                    className={`form-field ${
                                        invalidFormDetails.last_name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`last_name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.last_name
                                            ? formDetails.last_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="designation"
                                >
                                    Designation:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="designation"
                                    name="designation"
                                    type="text"
                                    autoComplete="designation"
                                    className={`form-field ${
                                        invalidFormDetails.designation
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`designation`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.designation
                                            ? formDetails.designation
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="email">
                                    Email address:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    autoComplete="email"
                                    className={`form-field ${
                                        invalidFormDetails.email
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`email`]:
                                                event.target.value.trim(),
                                        }));
                                    }}
                                    value={
                                        formDetails.email
                                            ? formDetails.email
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="contact_number"
                                >
                                    Contact number:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="contact_number"
                                    name="contact_number"
                                    type="text"
                                    autoComplete="contact_number"
                                    className={`form-field ${
                                        invalidFormDetails.contact_number
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`contact_number`]:
                                                event.target.value.trim(),
                                        }));
                                    }}
                                    value={
                                        formDetails.contact_number
                                            ? formDetails.contact_number
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="alternative_contact_number"
                                >
                                    Alternative contact number:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="alternative_contact_number"
                                    name="alternative_contact_number"
                                    type="text"
                                    autoComplete="alternative_contact_number"
                                    className={`form-field ${
                                        invalidFormDetails.alternative_contact_number
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`alternative_contact_number`]:
                                                event.target.value.trim(),
                                        }));
                                    }}
                                    value={
                                        formDetails.alternative_contact_number
                                            ? formDetails.alternative_contact_number
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    User type:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="user_type"
                                    name="user_type"
                                    autoComplete="user_type"
                                    className={`${
                                        invalidFormDetails.user_type
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`user_type`]: event.target.value,
                                        }));
                                        loadUserTypePermissions(
                                            event.target.value
                                        );
                                    }}
                                    value={
                                        formDetails.user_type
                                            ? formDetails.user_type
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select user type
                                    </option>
                                    {userTypes.map((userType) => {
                                        return (
                                            <option
                                                key={userType.id}
                                                value={userType.id}
                                            >
                                                {userType.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="departments"
                                >
                                    Department / Division:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="departments"
                                    name="departments"
                                    isMulti
                                    options={departments}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.departments
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`departments`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(departments).length !== 0
                                            ? formDetails?.departments?.map(
                                                  (selectedOption) => {
                                                      return departments[
                                                          departments.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="managers">
                                    Reporting manager(s):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="managers"
                                    name="managers"
                                    isMulti
                                    options={managers}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.managers
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`managers`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(managers).length !== 0
                                            ? formDetails?.managers?.map(
                                                  (selectedOption) => {
                                                      return managers[
                                                          managers.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        {formDetails.user_type !== "selected" && (
                            <>
                                <h3 className="font-bold text-gray-900 pt-4">
                                    Section/Group Permissions
                                </h3>

                                <div className="grid grid-cols-12">
                                    {programmePermissions.length ? (
                                        programmePermissions.map(
                                            (permission) => (
                                                <div
                                                    key={permission.id}
                                                    className="col-span-6"
                                                >
                                                    <input
                                                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                        type="checkbox"
                                                        id={permission.id}
                                                        onChange={(event) => {
                                                            selectedPermissions =
                                                                event.target
                                                                    .checked
                                                                    ? [
                                                                          ...formDetails.permissions,
                                                                          permission.id,
                                                                      ]
                                                                    : [
                                                                          ...formDetails.permissions,
                                                                      ].filter(
                                                                          (
                                                                              id
                                                                          ) =>
                                                                              id !==
                                                                              permission.id
                                                                      );
                                                            setUserPermissions(
                                                                selectedPermissions
                                                            );
                                                            setFormDetails(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    [`permissions`]:
                                                                        selectedPermissions,
                                                                })
                                                            );
                                                        }}
                                                        checked={
                                                            userPermissions.indexOf(
                                                                permission.id
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label inline-block text-gray-800 hover:cursor-pointer"
                                                        htmlFor={permission.id}
                                                    >
                                                        {permission.codename
                                                            .replace("_", " ")
                                                            .toUpperCase()}
                                                    </label>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div className="col-span-12">
                                            <em>
                                                No permissions available for
                                                programmes.
                                            </em>
                                        </div>
                                    )}
                                </div>

                                <h3 className="font-bold text-gray-900 pt-4">
                                    Project Permissions
                                </h3>

                                <div className="grid grid-cols-12">
                                    {projectPermissions.length ? (
                                        projectPermissions.map((permission) => (
                                            <div
                                                key={permission.id}
                                                className="col-span-6"
                                            >
                                                <input
                                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                    type="checkbox"
                                                    id={permission.id}
                                                    onChange={(event) => {
                                                        selectedPermissions =
                                                            event.target.checked
                                                                ? [
                                                                      ...formDetails.permissions,
                                                                      permission.id,
                                                                  ]
                                                                : [
                                                                      ...formDetails.permissions,
                                                                  ].filter(
                                                                      (id) =>
                                                                          id !==
                                                                          permission.id
                                                                  );
                                                        setUserPermissions(
                                                            selectedPermissions
                                                        );
                                                        setFormDetails(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [`permissions`]:
                                                                    selectedPermissions,
                                                            })
                                                        );
                                                    }}
                                                    checked={
                                                        userPermissions.indexOf(
                                                            permission.id
                                                        ) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="form-check-label inline-block text-gray-800 cursor-pointer"
                                                    htmlFor={permission.id}
                                                >
                                                    {permission.codename
                                                        .replace("_", " ")
                                                        .toUpperCase()}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-span-12">
                                            <em>
                                                No permissions available for
                                                projects.
                                            </em>
                                        </div>
                                    )}
                                </div>

                                <h3 className="font-bold text-gray-900 pt-4">
                                    Task Permissions
                                </h3>

                                <div className="grid grid-cols-12">
                                    {taskPermissions.length ? (
                                        taskPermissions.map((permission) => (
                                            <div
                                                key={permission.id}
                                                className="col-span-6"
                                            >
                                                <input
                                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                    type="checkbox"
                                                    id={permission.id}
                                                    onChange={(event) => {
                                                        selectedPermissions =
                                                            event.target.checked
                                                                ? [
                                                                      ...formDetails.permissions,
                                                                      permission.id,
                                                                  ]
                                                                : [
                                                                      ...formDetails.permissions,
                                                                  ].filter(
                                                                      (id) =>
                                                                          id !==
                                                                          permission.id
                                                                  );
                                                        setUserPermissions(
                                                            selectedPermissions
                                                        );
                                                        setFormDetails(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [`permissions`]:
                                                                    selectedPermissions,
                                                            })
                                                        );
                                                    }}
                                                    checked={
                                                        userPermissions.indexOf(
                                                            permission.id
                                                        ) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="form-check-label inline-block text-gray-800 cursor-pointer"
                                                    htmlFor={permission.id}
                                                >
                                                    {permission.codename
                                                        .replace("_", " ")
                                                        .toUpperCase()}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-span-12">
                                            <em>
                                                No permissions available for
                                                tasks.
                                            </em>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add ${
                                          userTypeParam === "administrators"
                                              ? `Administrator`
                                              : `User`
                                      }`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } ${
                            userTypeParam === "administrators"
                                ? `administrator`
                                : `user`
                        }:<br /><strong>${
                            formDetails.first_name + " " + formDetails.last_name
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
