import { Routes, Route, Outlet } from 'react-router-dom'
import { PORTAL } from '../routes/portal'
import RiskRegister from "../pages/risks/risk-register";
import InfrastructurePlanning from "../pages/infrastructure/planning";
import InfrastructureDashboard from "../pages/infrastructure/dashboard";

export default function InfrastructureRoutes() {
  
  return (
    <>
      <Routes>
        <Route index element={<InfrastructureDashboard />} />
        <Route
          path={PORTAL.INFRASTRUCTURE_PLANNING.replace(PORTAL.INFRASTRUCTURE, '')}
          element={<InfrastructureDashboard />}
        />
      </Routes>
      <Outlet />
    </>
  )
}
