import {useEffect, useState, useContext} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import PageHeader from "../../components/page-header";
import Table from "../../components/table";
import {compareDesc} from "date-fns";
import {useTextFormatHook} from "../../hooks/text-formatter";
import {useDataLoaderHook} from "../../hooks/data-loader";
import {ApiConsumer} from "../../api/ApiConsumer";
import {NavLink} from "react-router-dom";
import {GLOBAL} from "../../constants/global";
import {API_ROUTES} from "../../routes/api";
import {PORTAL} from "../../routes/portal";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {AppLoader} from "../../components/loader/app-loader";
import {AppLoaderContext} from "../../contexts/app-loader";

export default function IndividualPerformance() {
    let {setLoading} = useContext(AppLoaderContext);
    let {normaliseTableData} = useTextFormatHook();
    let {loadMultiple} = useDataLoaderHook();
    const [formDetails, setFormDetails] = useState({});
    const [departments, setDepartments] = useState([]);
    const [projectsReport, setProjectsReport] = useState([]);

    const addProjectLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            console.log(row)
            row.normalised.description = (
                <NavLink
                    to={`${PORTAL.TASKS_BY_PROJECT}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.description}
                </NavLink>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }
        console.log(API_ROUTES.RISK.REGISTER)
        ApiConsumer.get(API_ROUTES.RISK.REGISTER, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                console.log('Sort done')

                /*let normalisedProjectData = normaliseTableData(
                    GLOBAL.RISK_HEADERS,
                    res.data
                );*/
                console.log(res.data)
                setProjectsReport(addProjectLinksToTableData({
                        normalised: res.data
                    })
                );
                console.log("set done")
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };



    useEffect(() => {
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        return () => {
            setDepartments([]);
        };
    }, []);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setProjectsReport([]);
    };

    return (
        <>
            <PageHeader pageHeaderName={`Individual Performance`}/>

            <div className="relative py-2">
                <AppLoader
                    pageContent={
                        <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                            <div className="grid grid-cols-12 space-between">
                                <div className="col-span-4">
                                    <label className="text-sm" htmlFor="departments">
                                        Departments:
                                    </label>
                                    <Select
                                        id="departments"
                                        name="departments"
                                        placeholder="All departments"
                                        isMulti
                                        options={departments}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`departments`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(departments).length !== 0 &&
                                            formDetails.departments !== undefined
                                                ? formDetails?.departments?.map(
                                                    (selectedOption) => {
                                                        return departments[
                                                            departments.findIndex(
                                                                (option) =>
                                                                    option.value ===
                                                                    selectedOption
                                                            )
                                                            ];
                                                    }
                                                )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-7"/>
                                <button
                                    type="button"
                                    className="button flex-shrink-0 ml-2 mt-5 align-right"
                                    onClick={() => searchReport()}
                                >
                                    <div
                                        className="flex items-center pointer-events-none mr-1"
                                        aria-hidden="true"
                                    >
                                        <MagnifyingGlassIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    Search
                                </button>
                            </div>
                        </div>
                    }
                />
            </div>
            <Table
                tableTitle={`Individuals Results`}
                tableHeaders={GLOBAL.INDIVIDUAL_PERFORMANCE_HEADERS}
                tableData={projectsReport}
                fontSize="text-xs"
            />
        </>
    );
}
