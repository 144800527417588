import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as Sentry from "@sentry/react";
// import {BrowserTracing} from "@sentry/tracing";
import {createRoot} from 'react-dom/client';

// Sentry.init({
//     dsn: "https://a4cf63d4348442328b0d75b25e5d1445@o1149507.ingest.sentry.io/6221756",
//     integrations: [new BrowserTracing()],
//     environment: "uat",
//     tracesSampleRate: 1.0,
// });

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App tab="app"/>);

reportWebVitals();


