import { Routes, Route, Outlet } from 'react-router-dom'
import { PORTAL } from '../routes/portal'
import IndividualPerformance from "../pages/individual-performance/individual-performance";

export default function PerformanceRoutes() {
    console.log('RISK')
  return (
    <>
      <Routes>
        <Route index element={<IndividualPerformance />} />
        <Route
          path={PORTAL.INDIVIDUAL_PERFORMANCE.replace(PORTAL.PERFORMANCE, '')}
          element={<IndividualPerformance />}
        />
      </Routes>
      <Outlet />
    </>
  )
}
