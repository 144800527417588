import { useEffect, useState, Fragment, useContext } from "react";
import { PORTAL } from "../routes/portal";
import { NavLink, useMatch, useLocation } from "react-router-dom";
import {
    PencilIcon,
    PlusCircleIcon,
    ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { compareDesc } from "date-fns";
import { useTextFormatHook } from "../hooks/text-formatter";
import { usePermissionsHook } from "../hooks/permissions";
import { AuthenticationContext } from "../contexts/authentication";
import PageHeader from "../components/page-header";
import TableHeader from "../components/table-header";
import CardWide from "../components/card-wide";
import AddEditProject from "../components/forms/add-edit-project";
import Modal from "../components/modal";
import Alert from "../components/alert";
import AddEditSection from "../components/forms/add-edit-section";
import * as Sentry from "@sentry/react";
import { ApiConsumer } from "../api/ApiConsumer";
import { API_ROUTES } from "../routes/api";
import DownloadAsPdf from "../components/share/download-pdf";
import { GLOBAL } from "../constants/global";
import { useAuthentication } from "../hooks/authentication";
import { PERMISSIONS } from "../constants/permissions";
import Extension from "../components/extension";
import { AppLoader } from "../components/loader/app-loader";
import { AppLoaderContext } from "../contexts/app-loader";
import { useTermsHook } from "../hooks/terms";

export default function Projects() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    let { setLoading } = useContext(AppLoaderContext);
    let { getTermYearByIndex } = useTermsHook();

    /* Projects by programme */
    const programmesByKpa = useMatch(
        PORTAL.PROJECTS_BY_SECTION + "/:programme_id"
    );
    let programmeId = programmesByKpa?.params?.programme_id;

    /* Projects by year */
    const projectsByYear = useMatch(PORTAL.PROJECTS + "/:year");
    let year = projectsByYear?.params?.year;

    /* Projects by kpa */
    const projectsByKpa = useMatch(PORTAL.PROJECTS + "/:year/:kpa_id");
    let kpaId = projectsByKpa?.params?.kpa_id;
    if (year === undefined) year = projectsByKpa?.params?.year;

    /* Projects by kpi */
    const projectsByKpi = useMatch(PORTAL.PROJECTS + "/:year/:kpa_id/:kpi_id");
    let kpiId = projectsByKpi?.params?.kpi_id;
    if (year === undefined) year = projectsByKpi?.params?.year;
    if (kpaId === undefined) kpaId = projectsByKpi?.params?.kpa_id;

    /* Projects by user */
    const projectsByUser = useMatch(PORTAL.PROJECTS_BY_USER + "/:user_id");
    let userId = projectsByUser?.params?.user_id;

    /* Set project API url */
    let projectAPIURL = API_ROUTES.PROJECT.PROJECTS;

    if (programmeId)
        projectAPIURL = API_ROUTES.PROJECT.PROJECTS_BY_SECTION(programmeId);

    if (userId) projectAPIURL = API_ROUTES.PROJECT.PROJECTS_BY_USER(userId);

    if (year) projectAPIURL = API_ROUTES.PROJECT.PROJECTS_BY_YEAR(year);

    if (year && kpaId)
        projectAPIURL = API_ROUTES.PROJECT.PROJECTS_BY_YEAR_KPA(year, kpaId);

    if (year && kpaId && kpiId)
        projectAPIURL = API_ROUTES.PROJECT.PROJECTS_BY_KPI(kpiId);

    let { normaliseTableData } = useTextFormatHook();

    let { hasPermission } = useAuthentication();
    const [programme, setProgramme] = useState({});
    const [projects, setProjects] = useState([]);
    const [user, setUser] = useState({});
    const [kpa, setKpa] = useState({});
    const [kpi, setKpi] = useState({});

    const [openAddEditProject, setOpenAddEditProject] = useState(false);
    const [openAddEditSection, setOpenAddEditSection] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [editableProgramme, setEditableProgramme] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    let { getTermIndexByYear } = useTermsHook();
    const [yearIndex, setYearIndex] = useState(1);

    const pageHeaderButtons = [
        <NavLink
            to={`${PORTAL.SECTIONS_BY_KPA}/${programme?.kpa?.id}`}
            className={`button-default`}
        >
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Programmes
        </NavLink>,
    ];
    const pageHeaderIcons = [];
    const subHeaderButtons = [
        year &&
        !userId &&
        /*!isAfter(new Date(), new Date(programme.end_date)) &&*/
        hasPermission(PERMISSIONS.PROJECTS.CAN_ADD_PROJECT) ? (
            <button
                type="button"
                onClick={() => setOpenAddEditProject(true)}
                className={`button`}
            >
                <span className="button-icon">
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Add Project
            </button>
        ) : null,
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.PROJECT}/${row.id}`}
                    className={`brand-link underline`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {
        setLoading(true);
        ApiConsumer.get(projectAPIURL)
            .then((res) => {
                console.log(res.data);
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );

                res.data = res.data.filter(
                    (project) => project.active === true
                );

                if (
                    projectAPIURL === API_ROUTES.PROJECT.PROJECTS &&
                    searchTerm !== null &&
                    searchTerm !== ""
                ) {
                    let projects = res.data;
                    res.data = projects.filter((p) =>
                        p.name.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                let normalisedData = normaliseTableData(
                    GLOBAL.PROJECT_HEADERS,
                    res.data
                );
                setProjects(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setProgramme({});
            setProjects([]);
            setUser({});
            setKpa({});
            setOpenAddEditProject(false);
            setOpenAddEditSection(false);
            setEditableRow({});
            setEditableProgramme({});
            setRowUpdated(false);
            setFilterString("");
            setFilteredData([]);
        };
    }, [projectAPIURL, programmeId, userId]);

    useEffect(() => {
        if (userId) {
            ApiConsumer.get(API_ROUTES.USERS.USER_DETAIL(userId))
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }

        if (programmeId) {
            ApiConsumer.get(API_ROUTES.SECTION.SECTION_DETAIL(programmeId))
                .then((res) => {
                    setProgramme(
                        normaliseTableData(GLOBAL.SECTION_HEADERS, [
                            res.data,
                        ])[0]
                    );
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }

        if (kpaId) {
            ApiConsumer.get(API_ROUTES.CONTENT.KPA_DETAIL(kpaId))
                .then((res) => {
                    setKpa(res.data);
                    if (year)
                        setYearIndex(getTermIndexByYear(res.data?.term, year));
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }

        if (kpiId) {
            ApiConsumer.get(API_ROUTES.CONTENT.KPI_DETAIL(kpiId))
                .then((res) => {
                    setKpi(res.data[0]);
                })
                .catch((err) => {
                    Sentry.captureException(err);
                })
                .finally(() => {});
        }
    }, [userId, programmeId, kpaId, projectAPIURL]);

    const onCloseAddEditProject = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditProject(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditProject(false)
            : setOpenAddEditProject(true);
    }, [editableRow]);

    const onCloseAddEditSection = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableProgramme({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditSection(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableProgramme).length === 0
            ? setOpenAddEditSection(false)
            : setOpenAddEditSection(true);
    }, [editableProgramme]);

    return (
        <>
            <Modal
                modalHeaderTag={
                    kpa?.name !== undefined ? `KPA: ` + kpa.name : ""
                }
                modalHeader={
                    Object.keys(editableProgramme).length === 0
                        ? `Add New Section/Group`
                        : `Edit Section/Group: ${editableProgramme.name}`
                }
                modalContent={
                    <AddEditSection
                        type={
                            programme?.type?.id !== undefined
                                ? programme.type.id
                                : ""
                        }
                        kpa={kpa?.id !== undefined ? kpa.id : ""}
                        setOpen={onCloseAddEditSection}
                        editableRow={editableProgramme}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditSection}
                setOpen={onCloseAddEditSection}
            />

            <Modal
                modalHeaderTag={
                    editableRow?.kpa?.name !== undefined
                        ? editableRow?.kpa?.name
                        : ``
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Project <span class="header-tag-red">YEAR ${yearIndex} (${year})</span>`
                        : `Edit Project: ${editableRow.name} <span class="header-tag-red">YEAR ${yearIndex} (${year})</span>`
                }
                modalContent={
                    <AddEditProject
                        year={
                            editableRow?.year !== undefined
                                ? editableRow.year
                                : year
                        }
                        kpaId={
                            editableRow?.kpa?.id !== undefined
                                ? editableRow.kpa.id
                                : kpaId
                        }
                        kpi={kpi}
                        setOpen={onCloseAddEditProject}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditProject}
                setOpen={onCloseAddEditProject}
            />

            {programmeId && (searchTerm === null || searchTerm === "") && (
                <PageHeader
                    pageHeaderName={`${
                        programme?.name !== undefined
                            ? `Section/Group: ` + programme.name
                            : ""
                    } <span class="header-tag-green">
                ${kpa?.name !== undefined ? `KPA: ` + kpa.name : ""}
              </span>`}
                    pageHeaderButtons={pageHeaderButtons}
                    pageHeaderIcons={pageHeaderIcons}
                />
            )}
            <PageHeader
                pageHeaderName={`${
                    user?.first_name !== undefined &&
                    user?.last_name !== undefined
                        ? user.first_name + " " + user.last_name + " "
                        : ""
                }${!programmeId && !userId ? "Projects By " : ""}
                 KPI (${projects?.length}) ${
                    searchTerm !== null && searchTerm !== ""
                        ? ' Search results for "' + searchTerm + '"'
                        : ""
                } ${
                    year !== undefined
                        ? `<span class="header-tag-red">YEAR ${yearIndex} (${year})</span>`
                        : ``
                } ${
                    kpa?.name !== undefined
                        ? `<span class="header-tag-red">` +
                          kpa?.name +
                          `</span>`
                        : ``
                }`}
                pageHeaderButtons={[
                    <NavLink
                        to={`${PORTAL.SDBIP_KPIS_BY_KPA}/${kpa?.id}/${kpa?.term?.id}/${year}`}
                        className={`button-default`}
                    >
                        <span className="button-icon">
                            <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </span>
                        Back to SDBIP Indicators
                    </NavLink>,
                ]}
            />
            <PageHeader
                pageHeaderName={`KPI: ${
                    kpi?.indicator_name !== undefined ? kpi?.indicator_name : ``
                }`}
                pageHeaderButtons={subHeaderButtons}
            />
            {hasPermission(PERMISSIONS.PROJECTS.CAN_VIEW_PROJECT) ? (
                <div className="relative">
                    <AppLoader
                        pageContent={
                            projects.length ? (
                                <>
                                    <TableHeader
                                        tableTitle={`${
                                            kpi?.indicator_name !== undefined
                                                ? kpi.indicator_name + " - "
                                                : ""
                                        }${
                                            user?.first_name !== undefined &&
                                            user?.last_name !== undefined
                                                ? user.first_name +
                                                  " " +
                                                  user.last_name +
                                                  " "
                                                : ""
                                        }Project List`}
                                        tableHeaders={GLOBAL.PROJECT_HEADERS}
                                        tableData={projects}
                                        filterString={filterString}
                                        setFilterString={setFilterString}
                                        filteredData={filteredData}
                                        setFilteredData={setFilteredData}
                                    />

                                    <div className="mx-full my-5">
                                        {(filterString.length ||
                                        filteredData.length
                                            ? filteredData
                                            : projects
                                        ).map((project, i) => (
                                            <Fragment key={i}>
                                                <CardWide
                                                    row={project}
                                                    editable={true}
                                                    setEditableRow={
                                                        setEditableRow
                                                    }
                                                    reloadProjectData={loadData}
                                                    tableHeaders={
                                                        GLOBAL.PROJECT_HEADERS
                                                    }
                                                />
                                            </Fragment>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="mx-full px-8 my-4 text-center">
                                    <Alert
                                        type={`danger`}
                                        message={`There are no projects added under ${
                                            programme?.name !== undefined
                                                ? programme.name
                                                : ""
                                        } ${
                                            user?.first_name !== undefined &&
                                            user?.last_name !== undefined
                                                ? user.first_name +
                                                  " " +
                                                  user.last_name
                                                : ""
                                        } ${year !== undefined ? year : ""}${
                                            kpa?.name !== undefined
                                                ? ` for "` +
                                                  kpi?.indicator_name +
                                                  `"`
                                                : ``
                                        }
                          ${
                              searchTerm !== null && searchTerm !== ""
                                  ? searchTerm
                                  : ""
                          }`}
                                    />
                                </div>
                            )
                        }
                    />
                </div>
            ) : (
                <div className="mx-full px-8 my-4 text-center">
                    <Alert
                        type={`danger`}
                        message={`You do not have permission to view projects. Please contact the system administrator.`}
                    />
                </div>
            )}
        </>
    );
}
