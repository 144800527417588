import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import Modal from "../components/modal";
import Delete from "../components/delete";
import AddEditUser from "../components/forms/add-edit-user";
import { useTextFormatHook } from "../hooks/text-formatter";
import { ApiConsumer } from "../api/ApiConsumer";
import { Navigate, NavLink, useMatch } from "react-router-dom";
import { API_ROUTES } from "../routes/api";
import { APP_ID } from "../config";
import { PORTAL } from "../routes/portal";
import { GLOBAL } from "../constants/global";
import * as Sentry from "@sentry/react";

export default function Users() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(APP_ID));
    let { normaliseTableData } = useTextFormatHook();
    const userTypeParam = useMatch(PORTAL.USERS + "/:type")?.params?.type;
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditUser, setOpenAddEditUser] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const pageHeaderButtons = [
        <button
            type="button"
            onClick={() => setOpenAddEditUser(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add {userTypeParam === "administrators" ? `Administrator` : `User`}
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "Name",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "designation",
            value: "Designation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "email",
            value: "Email Address",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "contact_number",
            value: "Contact Number",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "alternative_contact_number",
            value: "Alt Contact Number",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "user_type",
            value: "User Type",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "departments",
            value: "Department / Division",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "managers",
            value: "Manager(s)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.SECTIONS_BY_USER}/${row.id}`}
                        className={`brand-link`}
                    >
                        {row.normalised.name}
                    </NavLink>
                    <Delete
                        apiRoute={API_ROUTES.USERS.USER_DETAIL}
                        rowData={row}
                        loadData={loadData}
                        titleValue={"User"}
                    />
                </>
            );
            row.name = row.first_name + " " + row.last_name;
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                //Map managers
                res.data.map((user) => {
                    if (Object.keys(user.managers).length !== 0) {
                        user.managers = res.data.filter(
                            (manager) =>
                                user.managers.indexOf(manager.id) !== -1
                        );
                    }
                });

                function AdminFilter(user_type) {
                    return user_type === GLOBAL.USER_TYPES.ADMINISTRATOR;
                }

                let normalisedData = normaliseTableData(
                    tableHeaders,
                    userTypeParam === "administrators"
                        ? res.data.filter((type) =>
                              AdminFilter(type?.user_type?.name)
                          )
                        : res.data.filter(
                              (type) => !AdminFilter(type?.user_type?.name)
                          )
                );

                setUsers(addLinksToTableData(normalisedData));
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setUsers([]);
            setLoaded(false);
            setOpenAddEditUser(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, [userTypeParam]);

    const onCloseAddEditUser = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditUser(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditUser(false)
            : setOpenAddEditUser(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== GLOBAL.USER_TYPES.ADMINISTRATOR)
        return <Navigate to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New ${
                              userTypeParam === "administrators"
                                  ? `Administrator`
                                  : `User`
                          }`
                        : `Edit ${
                              userTypeParam === "administrators"
                                  ? `Administrator`
                                  : `User`
                          }: ${
                              editableRow.first_name +
                              " " +
                              editableRow.last_name
                          }`
                }
                modalContent={
                    <AddEditUser
                        userTypeParam={userTypeParam}
                        setOpen={onCloseAddEditUser}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditUser}
                setOpen={onCloseAddEditUser}
            />

            <PageHeader
                pageHeaderName={`${
                    userTypeParam === "administrators"
                        ? `System Administrators`
                        : `System Users / Staff`
                } (${users?.length})`}
                pageHeaderButtons={pageHeaderButtons}
            />

            {loaded && (
                <Table
                    tableTitle={`${
                        userTypeParam === "administrators"
                            ? `System Administrators`
                            : `System Users / Staff`
                    }`}
                    tableHeaders={tableHeaders}
                    tableData={users}
                    editable={true}
                    setEditableRow={setEditableRow}
                />
            )}
        </>
    );
}
