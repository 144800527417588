import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { API_ROUTES } from "../../routes/api";
import { GLOBAL } from "../../constants/global";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Alert from "../../components/alert";
import { AppLoaderContext } from "../../contexts/app-loader";
import AddRemoveMultipleGoals from "../../components/forms/add-remove-multiple-goals";
import { useDataLoaderHook } from "../../hooks/data-loader";

export default function AddEditKpa({
    term,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { loadMultiple } = useDataLoaderHook();
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();

    const [ndpChapters, setNdpChapters] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "name", display: "KPA name" },
        { name: "ndp_chapters", display: "NDP chapter(s)" },
    ];

    const validateAddEditKpa = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.CONTENT.KPA_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.CONTENT.KPA, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        loadMultiple(API_ROUTES.PROJECT.NDP_CHAPTERS, setNdpChapters);
    }, []);

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`term`]: term?.id,
        }));
    }, [term]);

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`ndp_chapters`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`outcome`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`back_to_basics`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`provincial_pillars`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`regional_strategic_goals`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`regional_outcome`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`goals`]: [],
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`kpa`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
            let ndp_chapters = [];
            editableRow.ndp_chapters.forEach((chapter, i) => {
                ndp_chapters[i] = chapter.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`ndp_chapters`]: ndp_chapters,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`outcome`]: editableRow.outcome,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`back_to_basics`]: editableRow.back_to_basics,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`provincial_pillars`]: editableRow.provincial_pillars,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`regional_strategic_goals`]:
                    editableRow.regional_strategic_goals,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`regional_outcome`]: editableRow.regional_outcome,
            }));

            let goals = [];
            editableRow.goals.forEach((goal, i) => {
                goals[i] = {
                    strategic_goal: goal.strategic_goal,
                    goal_statement: goal.goal_statement,
                    goal_outcome: goal.goal_outcome,
                };
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`goals`]: goals,
            }));
        }

        return () => {
            setNdpChapters([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditKpa(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <span className="font-bold">Created:</span>{" "}
                            {format(
                                new Date(editableRow.created),
                                "dd MMM yyyy"
                            )}{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {format(
                                new Date(editableRow.updated),
                                "dd MMM yyyy"
                            )}{" "}
                        </div>
                    )}

                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    KPA name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="ndp_chapters"
                                >
                                    Which NDP chapter(s) does this KPA address:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="ndp_chapters"
                                    name="ndp_chapters"
                                    isMulti
                                    options={ndpChapters}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.ndp_chapters
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`ndp_chapters`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(ndpChapters).length !== 0
                                            ? formDetails?.ndp_chapters?.map(
                                                  (selectedOption) => {
                                                      return ndpChapters[
                                                          ndpChapters.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="outcome">
                                    National Outcome:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="outcome"
                                    name="outcome"
                                    autoComplete="outcome"
                                    className={`form-field ${
                                        invalidFormDetails.outcome
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`outcome`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.outcome
                                            ? formDetails.outcome
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="back_to_basics"
                                >
                                    Back to Basics (B2B):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="back_to_basics"
                                    name="back_to_basics"
                                    autoComplete="back_to_basics"
                                    className={`form-field ${
                                        invalidFormDetails.back_to_basics
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`back_to_basics`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.back_to_basics
                                            ? formDetails.back_to_basics
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="provincial_pillars"
                                >
                                    Provincial 10 Pillars:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="provincial_pillars"
                                    name="provincial_pillars"
                                    autoComplete="provincial_pillars"
                                    className={`form-field ${
                                        invalidFormDetails.provincial_pillars
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`provincial_pillars`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.provincial_pillars
                                            ? formDetails.provincial_pillars
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="regional_strategic_goals"
                                >
                                    Regional Strategic Goals (RSG):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="regional_strategic_goals"
                                    name="regional_strategic_goals"
                                    autoComplete="regional_strategic_goals"
                                    className={`form-field ${
                                        invalidFormDetails.regional_strategic_goals
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`regional_strategic_goals`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.regional_strategic_goals
                                            ? formDetails.regional_strategic_goals
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="regional_outcome"
                                >
                                    Regional Outcome:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="regional_outcome"
                                    name="regional_outcome"
                                    autoComplete="regional_outcome"
                                    className={`form-field ${
                                        invalidFormDetails.regional_outcome
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`regional_outcome`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.regional_outcome
                                            ? formDetails.regional_outcome
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="bg-gray-100 p-4 rounded-md">
                            <AddRemoveMultipleGoals
                                formDetails={formDetails}
                                setFormDetails={setFormDetails}
                            />
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add KPA`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } KPA:<br /><strong>${formDetails.name}</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
