import {tr} from "date-fns/locale";

export const GLOBAL = {
    TERMS: [1, 2, 3, 4, 5],
    QUARTERS: [1, 2, 3, 4],
    USER_TYPES: {
        ADMINISTRATOR: "Administrator",
        AUDITOR: "Auditor",
        STAFF_MEMBER: "Staff Member",
        SUPPORT_USER: "	Support User",
    },
    LAYERTYPE: {
        KPA: `kpa`,
        SECTION: `programme`,
        PROJECT: `project`,
        TASK: `task`,
    },
    STATUS: [
        {
            value: "poor",
            label: "POOR",
        },
        {
            value: "progressive",
            label: "PROGRESSIVE",
        },
        {
            value: "good",
            label: "GOOD",
        },
        {
            value: "achieved",
            label: "TARGET ACHIEVED",
        },
        {
            value: "exceeded",
            label: "EXCEEDED",
        },
        {
            value: "overdue",
            label: "OVERDUE",
        },
    ],
    SLIDER: [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-gray-400",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-orange-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "TARGET ACHIEVED",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-500",
        },
        {
            NAME: "EXCEEDED",
            MIN: 100,
            MAX: 101,
            STYLE: "bg-green-800",
        },
    ],
    SECTION_HEADERS: [
        {
            key: "name",
            value: "Section/Group",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief Overview of Section/Group",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "total_projects",
            value: "# of Projects",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "total_tasks",
            value: "# of Tasks",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        },
    ],
    PROJECT_HEADERS: [
        {key: "name", value: "Project", showOnTable: true, showOnPrint: true},
        {
            key: "year",
            value: "Year",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "kpa",
            value: "KPA",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "indicator",
            value: "KPI",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "programme",
            value: "Section/Group",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief Overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "idp_ref",
            value: "IDP Ref No:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "budget",
            value: "Draft Budget:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "second_budget",
            value: "2nd Draft Budget:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "actual_budget",
            value: "Final Budget:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "departments",
            value: "Department / Division:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "reporting_managers",
            value: "Reporting Manager(s)",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "deliverables",
            value: "Project Deliverables",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "progress",
            value: "Project Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Project Status",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "total_tasks",
            value: "# of Tasks",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        },
    ],
    TASK_HEADERS: [
        {
            key: "name",
            value: "Task",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "kpa",
            value: "KPA",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "kpi",
            value: "KPI",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "programme",
            value: "Section/Group",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "project",
            value: "Project",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "departments",
            value: "Department / Division:",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "expenditure",
            value: "Actual expenditure",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "responsible_person",
            value: "Responsible person",
            showOnTable: true,
            showOnPrint: true,
        },

        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        },
    ],
    PRINT: {
        PAGE: `page`,
        TABLE: `table`,
    },
    RISK_HEADERS: [
        {
            key: "description",
            value: "Risk",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "type",
            value: "Type",
            showOnTable: true,
            showOnPrint: true,
        },
         {
             key: "project",
             value: "Project",
             showOnTable: true,
             showOnPrint: true,
         },
        {
            key: "department",
            value: "Department",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "mitigation",
            value: "mitigation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "category",
            value: "category",
            showOnTable: true,
            showOnPrint: true,
        },
         {
             key: "inherent_ranking",
             value: "Inherent Ranking",
             showOnTable: true,
             showOnPrint: false,
         },
        {
             key: "inherent_ranking_print",
             value: "Inherent Ranking",
             showOnTable: false,
             showOnPrint: true,
         },
        {
            key: "residual_ranking",
            value: "Residual Ranking",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "potential_loss_impact",
            value: "Potential Loss Impact",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "likelihood",
            value: "Likelihood",
            showOnTable: true,
            showOnPrint: true,
        },{
            key: "perceived_control_effectiveness",
            value: "Perceived Control Effectiveness",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: true,
            showOnPrint: true,
        },
    ],
    INDIVIDUAL_PERFORMANCE_HEADERS: [
        {
            key: "name",
            value: "Name",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "surname",
            value: "Surname",
            showOnTable: true,
            showOnPrint: true,
        },
    ],
    AUDIT_ACTIONS_HEADERS: [
        {
            key: "action_description",
            value: "Description",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "responsible_party",
            value: "Responsible Person",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "target_completion_date",
            value: "Target Completion Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "completion_status",
            value: "Completion Status",
            showOnTable: true,
            showOnPrint: true,
        },
    ],
};
