import { useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { useDataLoaderHook } from "../../hooks/data-loader";
import { API_ROUTES } from "../../routes/api";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import { GLOBAL } from "../../constants/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../components/alert";
import Select from "react-select";
import { AppLoaderContext } from "../../contexts/app-loader";
import ModalUserDetails from "../../components/modal-user-details";
import Modal from "../../components/modal";
import AddRemoveMultipleDeliverable from "../../components/forms/add-remove-multiple-deliverables";

export default function AddEditProject({
    year,
    kpaId,
    kpi,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    console.log(kpi);
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    let { loadMultiple, loadDropdown } = useDataLoaderHook();

    const [programmes, setProgrammes] = useState([]);
    const [kpas, setKpas] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [reportingManagers, setReportingManagers] = useState([]);
    const [partners, setPartners] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    const formFields = [
        { name: "kpa", display: "KPA" },
        { name: "programme", display: "section/group" },
        { name: "name", display: "project name" },
        { name: "overview", display: "overwiew" },
        { name: "start_date", display: "project start date" },
        { name: "end_date", display: "project end date" },
        { name: "departments", display: "department / division" },
        { name: "reporting_managers", display: "reporting manager(s)" },
    ];

    const validateAddEditProject = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            console.log(formDetails);

            setLoading(true);
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.PROJECT.PROJECT_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.PROJECT.PROJECTS, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (user) =>
                        [
                            GLOBAL.USER_TYPES.STAFF_MEMBER,
                            GLOBAL.USER_TYPES.ADMINISTRATOR,
                        ].indexOf(user.user_type.name) !== -1
                );

                if (Object.keys(editableRow).length !== 0)
                    users = users.filter((user) => editableRow.id !== user.id);

                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });

                setReportingManagers(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, []);

    useEffect(() => {
        loadDropdown(API_ROUTES.SECTION.SECTIONS, setProgrammes);
        loadDropdown(API_ROUTES.CONTENT.KPA, setKpas);
        loadMultiple(API_ROUTES.PROJECT.PARTNERS, setPartners);
    }, []);

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`year`]: year,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator`]: kpi?.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpa`]: kpaId !== undefined ? kpaId : "selected",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`programme`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`overview`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`wardnum`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`longitude`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`latitude`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`start_date`]: new Date(),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`end_date`]: new Date(),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`idp_ref`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`budget`]: 0,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`second_budget`]: 0,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`actual_budget`]: 0,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`partners`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`reporting_managers`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`deliverables`]: [],
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`project_id`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`kpa`]: editableRow.kpa.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme`]: editableRow.programme.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`overview`]: editableRow.overview,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`wardnum`]: editableRow.wardnum,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`longitude`]: editableRow.longitude,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`latitude`]: editableRow.latitude,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`start_date`]: new Date(editableRow.start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`end_date`]: new Date(editableRow.end_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`idp_ref`]: editableRow.idp_ref,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`budget`]: editableRow.budget,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`second_budget`]: editableRow.second_budget,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`actual_budget`]: editableRow.actual_budget,
            }));

            let departments = [];
            editableRow.departments?.forEach((department, i) => {
                departments[i] = department.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`departments`]: departments,
            }));
            let partners = [];
            editableRow?.partners?.forEach((partner, i) => {
                partners[i] = partner.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`partners`]: partners,
            }));
            let reporting_managers = [];
            editableRow.reporting_managers?.forEach((manager, i) => {
                reporting_managers[i] = manager.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`reporting_managers`]: reporting_managers,
            }));
            let deliverables = [];
            editableRow.deliverables.forEach((deliverable, i) => {
                deliverables[i] = {
                    deliverable_date: deliverable.deliverable_date,
                    deliverable_description:
                        deliverable.deliverable_description,
                };
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`deliverables`]: deliverables,
            }));
        }

        return () => {
            setProgrammes([]);
            setKpas([]);
            setDepartments([]);
            setReportingManagers([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditProject(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>
                        </>
                    )}

                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    <strong>KPA:</strong>
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                <strong>
                                    {kpas.map((kpa) =>
                                        kpa.id === formDetails.kpa
                                            ? kpa.name
                                            : null
                                    )}
                                </strong>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    KPI (indicator):
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                {kpi?.indicator_name}
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="programme">
                                    Section/Group:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="programme"
                                    name="programme"
                                    autoComplete="programme"
                                    className={`${
                                        invalidFormDetails.programme
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`programme`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.programme
                                            ? formDetails.programme
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select section/group
                                    </option>
                                    {programmes.map((programme) => {
                                        return (
                                            <option
                                                key={programme.id}
                                                value={programme.id}
                                            >
                                                {programme.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Project name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="overview">
                                    Brief overview:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="overview"
                                    name="overview"
                                    autoComplete="overview"
                                    className={`form-field ${
                                        invalidFormDetails.overview
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`overview`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.overview
                                            ? formDetails.overview
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="wardnum">
                                    Ward number:{" "}
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="wardnum"
                                    name="wardnum"
                                    type="text"
                                    autoComplete="wardnum"
                                    className={`form-field ${
                                        invalidFormDetails.wardnum
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`wardnum`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.wardnum
                                            ? formDetails.wardnum
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="longitude">
                                    GPS Coordinates (Long):{" "}
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="longitude"
                                    name="longitude"
                                    type="text"
                                    autoComplete="longitude"
                                    className={`form-field ${
                                        invalidFormDetails.longitude
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`longitude`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.longitude
                                            ? formDetails.longitude
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="latitude">
                                    GPS Coordinates (Lat):{" "}
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="latitude"
                                    name="latitude"
                                    type="text"
                                    autoComplete="latitude"
                                    className={`form-field ${
                                        invalidFormDetails.latitude
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`latitude`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.latitude
                                            ? formDetails.latitude
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="start_date">
                                    Project start date:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    /* minDate={new Date()}
                                    maxDate={new Date()} */
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.start_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`start_date`]: event,
                                        }));
                                        if (
                                            isAfter(event, formDetails.end_date)
                                        )
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`end_date`]: event,
                                            }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="end_date">
                                    Project end date:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    /* minDate={
                                        isAfter(
                                            new Date(programme.start_date),
                                            formDetails.start_date
                                        )
                                            ? new Date(programme.start_date)
                                            : new Date(formDetails.start_date)
                                    }
                                    maxDate={new Date(programme.end_date)} */
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.end_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`end_date`]: event,
                                        }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="partners">
                                    Funded by :{" "}
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="partners"
                                    name="partners"
                                    isMulti
                                    options={partners}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.partners
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`partners`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(partners).length !== 0
                                            ? formDetails?.partners?.map(
                                                  (selectedOption) => {
                                                      return partners[
                                                          partners.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="idp_ref">
                                    IDP Ref No:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="idp_ref"
                                    name="idp_ref"
                                    type="text"
                                    autoComplete="idp_ref"
                                    className={`form-field ${
                                        invalidFormDetails.idp_ref
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`idp_ref`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.idp_ref
                                            ? formDetails.idp_ref
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="budget">
                                    Draft Budget (R):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="budget"
                                    name="budget"
                                    type="text"
                                    autoComplete="budget"
                                    className={`form-field ${
                                        invalidFormDetails.budget
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`budget`]: !isNaN(
                                                event.target.value
                                            )
                                                ? parseFloat(event.target.value)
                                                : 0,
                                        }));
                                    }}
                                    value={
                                        formDetails.budget
                                            ? formDetails.budget
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="second_budget"
                                >
                                    2nd Draft Budget (R):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="second_budget"
                                    name="second_budget"
                                    type="text"
                                    autoComplete="second_budget"
                                    className={`form-field ${
                                        invalidFormDetails.second_budget
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`second_budget`]: !isNaN(
                                                event.target.value
                                            )
                                                ? parseFloat(event.target.value)
                                                : 0,
                                        }));
                                    }}
                                    value={
                                        formDetails.second_budget
                                            ? formDetails.second_budget
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="actual_budget"
                                >
                                    Final Budget (R):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="actual_budget"
                                    name="actual_budget"
                                    type="text"
                                    autoComplete="actual_budget"
                                    className={`form-field ${
                                        invalidFormDetails.actual_budget
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`actual_budget`]: !isNaN(
                                                event.target.value
                                            )
                                                ? parseFloat(event.target.value)
                                                : 0,
                                        }));
                                    }}
                                    value={
                                        formDetails.actual_budget
                                            ? formDetails.actual_budget
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <p className="mt-4 pt-4 text-sm font-semibold border-t border-gray-200">
                                Project Accountability
                            </p>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="departments"
                                >
                                    Department / Division:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="departments"
                                    name="departments"
                                    isMulti
                                    options={departments}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.departments
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`departments`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(departments).length !== 0
                                            ? formDetails?.departments?.map(
                                                  (selectedOption) => {
                                                      return departments[
                                                          departments.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="reporting_managers"
                                >
                                    Reporting manager(s):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="reporting_managers"
                                    name="reporting_managers"
                                    isMulti
                                    options={reportingManagers}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.reporting_managers
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`reporting_managers`]: [
                                                ...event,
                                            ].map((option) => option.value),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(reportingManagers)
                                            .length !== 0
                                            ? formDetails?.reporting_managers?.map(
                                                  (selectedOption) => {
                                                      return reportingManagers[
                                                          reportingManagers.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="bg-gray-100 p-4 rounded-md">
                            <AddRemoveMultipleDeliverable
                                formDetails={formDetails}
                                setFormDetails={setFormDetails}
                            />
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Project`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } project:<br /><strong>${formDetails.name}</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
