import { useEffect, useState, useContext } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import PageHeader from "../components/page-header";
import Table from "../components/table";
import { sub, add, compareDesc, isAfter } from "date-fns";
import { useTextFormatHook } from "../hooks/text-formatter";
import { useDataLoaderHook } from "../hooks/data-loader";
import { ApiConsumer } from "../api/ApiConsumer";
import { NavLink } from "react-router-dom";
import { GLOBAL } from "../constants/global";
import { API_ROUTES } from "../routes/api";
import { PORTAL } from "../routes/portal";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppLoader } from "../components/loader/app-loader";
import { AppLoaderContext } from "../contexts/app-loader";

export default function ProjectReports() {
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    let { loadMultiple } = useDataLoaderHook();
    const [formDetails, setFormDetails] = useState({});
    const [kpas, setKpas] = useState([]);
    const [programmes, setProgrammes] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [reportingManagers, setReportingManagers] = useState([]);
    const [projectsReport, setProjectsReport] = useState([]);

    const addProjectLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.TASKS_BY_PROJECT}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }
        ApiConsumer.post(API_ROUTES.REPORT.PROJECTS, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data = res.data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );
                //Filter by status
                res.data.map((row, i) => {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.progress < 100
                    )
                        res.data[i]["status"] = "OVERDUE".toLowerCase();
                    else
                        res.data[i]["status"] = GLOBAL.SLIDER.filter((x) =>
                            row.progress <= 100
                                ? x.MIN <= row.progress &&
                                  x.MAX >= row.progress
                                : x.MAX > 100
                        )[0].NAME.toLowerCase();
                });
                if (
                    formDetails.project_status !== undefined &&
                    formDetails.project_status.length !== 0
                )
                    res.data = res.data.filter(
                        (report) =>
                            formDetails.project_status.indexOf(
                                report.status
                            ) !== -1
                    );

                let normalisedProjectData = normaliseTableData(
                    GLOBAL.PROJECT_HEADERS,
                    res.data
                );
                setProjectsReport(
                    addProjectLinksToTableData(normalisedProjectData)
                );
            })
            .catch((err) => {
                Sentry.captureException(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onProjectDateChange = (dates) => {
        const [start, end] = dates;

        setFormDetails((prevState) => ({
            ...prevState,
            [`project_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_end_date`]: end,
        }));
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_start_date`]: sub(new Date(), { years: 1 }),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    useEffect(() => {
        loadMultiple(API_ROUTES.CONTENT.KPA, setKpas);
        loadMultiple(API_ROUTES.SECTION.SECTIONS, setProgrammes);
        loadMultiple(API_ROUTES.SECTION.DEPARTMENTS, setDepartments);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let userOptions = res.data.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setReportingManagers(userOptions);
            })
            .catch((err) => {
                Sentry.captureException(err);
            });

        return () => {
            setDepartments([]);
            setReportingManagers([]);
        };
    }, []);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpas`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programmes`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`reporting_managers`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`project_status`]: [],
        }));
        setProjectsReport([]);
    };

    return (
        <>
            <PageHeader pageHeaderName={`Project Reports`} />

            <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                <div className="grid grid-cols-12 space-x-4">
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="kpas">
                            KPAs:
                        </label>
                        <Select
                            id="kpas"
                            name="kpas"
                            placeholder="All kpas"
                            isMulti
                            options={kpas}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`kpas`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(kpas).length !== 0 &&
                                formDetails.kpas !== undefined
                                    ? formDetails?.kpas?.map(
                                          (selectedOption) => {
                                              return kpas[
                                                  kpas.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-9">
                        <label className="text-sm" htmlFor="programmes">
                            Select programmes:
                        </label>
                        <Select
                            id="programmes"
                            name="programmes"
                            placeholder="All programmes"
                            isMulti
                            options={programmes}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`programmes`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(programmes).length !== 0 &&
                                formDetails.programmes !== undefined
                                    ? formDetails?.programmes?.map(
                                          (selectedOption) => {
                                              return programmes[
                                                  programmes.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="border-b border-gray-200 mx-full px-4 sm:px-6 lg:px-8 my-3 pb-3">
                <div className="grid grid-cols-12 space-x-4">
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="departments">
                            Departments:
                        </label>
                        <Select
                            id="departments"
                            name="departments"
                            placeholder="All departments"
                            isMulti
                            options={departments}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`departments`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(departments).length !== 0 &&
                                formDetails.departments !== undefined
                                    ? formDetails?.departments?.map(
                                          (selectedOption) => {
                                              return departments[
                                                  departments.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>

                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="reporting_managers">
                            Reporting manager(s):
                        </label>
                        <Select
                            id="reporting_managers"
                            name="reporting_managers"
                            placeholder="All users"
                            isMulti
                            options={reportingManagers}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`reporting_managers`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(reportingManagers).length !== 0 &&
                                formDetails.reporting_managers !== undefined
                                    ? formDetails?.reporting_managers?.map(
                                          (selectedOption) => {
                                              return reportingManagers[
                                                  reportingManagers.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className="col-span-3">
                        <label className="text-sm" htmlFor="project_status">
                            Project status:
                        </label>
                        <Select
                            id="project_status"
                            name="project_status"
                            placeholder="All statuses"
                            isMulti
                            options={GLOBAL.STATUS}
                            classNamePrefix="multi-select"
                            className={`multi-select text-xs`}
                            onChange={(event) => {
                                setFormDetails((prevState) => ({
                                    ...prevState,
                                    [`project_status`]: [...event].map(
                                        (option) => option.value
                                    ),
                                }));
                            }}
                            defaultValue={0}
                            value={
                                Object.keys(GLOBAL.STATUS).length !== 0 &&
                                formDetails.project_status !== undefined
                                    ? formDetails?.project_status?.map(
                                          (selectedOption) => {
                                              return GLOBAL.STATUS[
                                                  GLOBAL.STATUS.findIndex(
                                                      (option) =>
                                                          option.value ===
                                                          selectedOption
                                                  )
                                              ];
                                          }
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="relative py-2">
                <AppLoader
                    pageContent={
                        <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 text-lg leading-6 text-gray-900 text-left items-center">
                            <span className="min-w-0 flex-1 items-center font-medium">
                                Project Results ({projectsReport.length})
                                <br />
                                <span
                                    className="brand-link text-sm font-normal"
                                    onClick={() => clearSearch()}
                                >
                                    Clear all search parameters
                                </span>
                            </span>

                            <div className="col-span-3">
                                <label className="text-sm" htmlFor="date_range">
                                    Date range:
                                </label>
                                <DatePicker
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.project_start_date}
                                    onChange={(event) =>
                                        onProjectDateChange(event)
                                    }
                                    className="form-field"
                                    startDate={formDetails.project_start_date}
                                    endDate={formDetails.project_end_date}
                                    selectsRange
                                />
                            </div>
                            <button
                                type="button"
                                className="button flex-shrink-0 ml-2 mt-5"
                                onClick={() => searchReport()}
                            >
                                <div
                                    className="flex items-center pointer-events-none mr-1"
                                    aria-hidden="true"
                                >
                                    <MagnifyingGlassIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                Search
                            </button>
                        </h2>
                    }
                />
            </div>
            <Table
                tableTitle={`Project Report Results`}
                tableHeaders={GLOBAL.PROJECT_HEADERS}
                tableData={projectsReport}
                fontSize="text-xs"
            />
        </>
    );
}
