import { useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "../../hooks/form-validation";
import { useDataLoaderHook } from "../../hooks/data-loader";
import { useTermsHook } from "../../hooks/terms";
import { API_ROUTES } from "../../routes/api";
import { ApiConsumer } from "../../api/ApiConsumer";
import * as Sentry from "@sentry/react";
import { GLOBAL } from "../../constants/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../components/alert";
import Select from "react-select";
import { AppLoaderContext } from "../../contexts/app-loader";
import ModalUserDetails from "../../components/modal-user-details";
import Modal from "../../components/modal";
import AddRemoveMultipleIndicators from "../../components/forms/add-remove-multiple-indicators";

export default function AddEditKpaIndicator({
    kpa,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    let { loadDropdown } = useDataLoaderHook();
    let { getTermYearByIndex } = useTermsHook();
    const [uoms, setUoms] = useState([]);

    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [indicatorUmo, setIndicatorUmo] = useState("");

    const formFields = [
        { name: "indicator_name", display: "KPI name" },
        { name: "indicator_umo", display: "unit of measurement (UOM)" },
        {
            name: "indicator_year1",
            display: "year 1 (" + getTermYearByIndex(kpa.term, 1) + ") target",
        },
        {
            name: "indicator_year2",
            display: "year 2 (" + getTermYearByIndex(kpa.term, 2) + ") target",
        },
        {
            name: "indicator_year3",
            display: "year 3 (" + getTermYearByIndex(kpa.term, 3) + ") target",
        },
        {
            name: "indicator_year4",
            display: "year 4 (" + getTermYearByIndex(kpa.term, 4) + ") target",
        },
        {
            name: "indicator_year5",
            display: "year 5 (" + getTermYearByIndex(kpa.term, 5) + ") target",
        },
    ];

    const validateAddEditIndicators = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.CONTENT.KPI_BY_KPA(kpa.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(
                    API_ROUTES.CONTENT.KPI_BY_KPA(kpa.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.METADATA.UOM, setUoms);

        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`kpa`]: kpa?.id !== undefined ? kpa.id : "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_umo`]: "selected",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_year1`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_year2`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_year3`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_year4`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`indicator_year5`]: "",
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_id`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_name`]: editableRow.indicator_name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_umo`]: editableRow.indicator_umo.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_year1`]: editableRow.indicator_year1,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_year2`]: editableRow.indicator_year2,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_year3`]: editableRow.indicator_year3,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_year4`]: editableRow.indicator_year4,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`indicator_year5`]: editableRow.indicator_year5,
            }));
        }

        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditIndicators(
                            Object.keys(editableRow).length
                        );
                    }}
                    method="POST"
                >
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="kpa">
                                    <strong>KPA:</strong>
                                </label>
                            </div>
                            <div className="col-span-8 text-sm">
                                <strong>{kpa?.name}</strong>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="indicator_name"
                                >
                                    KPI: <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="indicator_name"
                                    name="indicator_name"
                                    type="text"
                                    autoComplete="indicator_name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`indicator_name`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.indicator_name
                                            ? formDetails.indicator_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="indicator_umo"
                                >
                                    Unit Of Measurement:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="indicator_umo"
                                    name="indicator_umo"
                                    autoComplete="indicator_umo"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`indicator_umo`]:
                                                event.target.value,
                                        }));

                                        let umoTextValue =
                                            event.target.options[
                                                event.target.selectedIndex
                                            ].text;

                                        if (
                                            indicatorUmo.toUpperCase() !==
                                                umoTextValue.toUpperCase() &&
                                            (umoTextValue.toUpperCase() ===
                                                "PERCENTAGE" ||
                                                umoTextValue.toUpperCase() ===
                                                    "NUMBER")
                                        ) {
                                            GLOBAL.TERMS.map((year, i) =>
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`indicator_year` + year]:
                                                        "",
                                                }))
                                            );
                                        }

                                        setIndicatorUmo(umoTextValue);
                                    }}
                                    value={
                                        formDetails.indicator_umo
                                            ? formDetails.indicator_umo
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select Unit Of Measurement
                                    </option>
                                    {uoms.map((uom) => {
                                        return (
                                            <option key={uom.id} value={uom.id}>
                                                {uom.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {GLOBAL.TERMS.map((year, i) => (
                            <div key={i} className="grid grid-cols-12">
                                <div className="col-span-4">
                                    <label
                                        className="text-sm"
                                        htmlFor={`indicator_year` + year}
                                    >
                                        Year {year} (
                                        {getTermYearByIndex(kpa.term, year)})
                                        <br />
                                        {formDetails.indicator_umo !==
                                        "selected"
                                            ? indicatorUmo
                                            : ""}{" "}
                                        Target:{" "}
                                        <span className="text-red-600">*</span>
                                    </label>
                                </div>
                                <div className="col-span-8">
                                    <input
                                        id={`indicator_year` + year}
                                        name={`indicator_year` + year}
                                        type="text"
                                        autoComplete={`indicator_year` + year}
                                        className={`form-field ${
                                            invalidFormDetails.name
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) =>
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`indicator_year` + year]:
                                                    indicatorUmo.toUpperCase() ===
                                                        `PERCENTAGE` ||
                                                    indicatorUmo.toUpperCase() ===
                                                        `NUMBER`
                                                        ? event.target.value ===
                                                          "0"
                                                            ? "0"
                                                            : !isNaN(
                                                                  event.target
                                                                      .value
                                                              )
                                                            ? parseFloat(
                                                                  event.target
                                                                      .value
                                                              )
                                                            : "0"
                                                        : event.target.value,
                                            }))
                                        }
                                        value={
                                            formDetails[`indicator_year` + year]
                                                ? formDetails[
                                                      `indicator_year` + year
                                                  ]
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                        ))}

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Indicator`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } indicator:<br /><strong>${
                            formDetails.indicator_name
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
